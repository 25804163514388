.pai-template-default {
	.container {
		@media (max-width: 1230px) {
			width: 991px;
		}
		@media (max-width: 991px) {
			width: 782px;
		}
		@media (max-width: 767px) {
			width: 100%;
		}
	}
	.fb-like span { vertical-align:initial !important; }
	.tab-control{
		border: 2px solid #44748c;
		color: #44748c;
		padding: 10px 30px;
		text-transform: uppercase;
		text-align:center;
		margin-right:5px;
		cursor:pointer;
		@include transition-no-touch(all ease $animationSpeed);
		font-weight:bold;
		font-size: 14px;
		display: block;
		line-height: 20px;
	}

	.tab-control a{ color:#44748c;}

	.tab-control {
		@include touch-hover() {
			background: #44748c;
			color:#fff !important;
			text-decoration: none !important;
			a { color:#fff !important; }
		}
	}
	ul.tabs .current .tab-control{
		background: #44748c;
		color:#fff !important;
	}
	ul.tabs .current .tab-control a{
		color:#fff !important;
	}
	ul.tabs .current .tab-control a svg path{
		fill:#fff;
	}
	ul.tabs{
		margin: 0px;
		padding: 0px;
		list-style: none;
	}
	.tab-content{
		display: block;
		padding: 15px;
		margin-top:20px;
		margin-bottom:20px;
	}
	.tab-content.current{ display: inherit; }
	.tab-link{
		padding-left:2px !important;
		padding-right:2px !important;
	}
	.pai-data table thead tr td{ color: white !important; }
	.pai-table-head-top{ padding-top:5px; }
	.pai-table-head-bottom{ padding-bottom:5px; font-size:0.8em; }
	.tab-down-arrow-btn{
		position: absolute;
		width: 12px;
		right: 20px;
		top: 50%;
		fill: #44748c;
		transform: translateY(-50%);
		@include transition-no-touch(all ease $animationSpeed);
	}
	.tab-link a {
		@include touch-hover() {
			.tab-down-arrow-btn { fill: #fff; }
		}
	}
	.pai-data table{ width: 100%; }
	.pai-table-body-first{ width:36%; }
	.pai-table-body-second{ width:13%; }
	.pai-table-body-third{ width:16%; }
	.pai-table-body-fourth{ width:19%; }
	.pai-table-body-fifth{ width:16%; }

	.pai-box h4{
		color:#44748c;
		font-size: 1.1em;
	}
	.pai-box h5 span{ font-size:1.2em; }
	.pai-box img{
		display:block;
		float:left;
		margin-right:20px;
		margin-bottom:20px;
	}
	.highcharts-container{
		height: 300px !important;
		width: 100% !important;
	}
	.highcharts-container svg { 
		height: 300px !important;
		width: 100% !important;
	}

	.pai-box a{
		color:#44748c !important;
	}
	.pai-box a {
		@include touch-hover() { color:#5480b5 !important; }
	}

	.pai-box p strong{
		color:#44748c !important;
	}


	@media only screen and (max-width: 991px) {
		.tab-control {
			.tab-down-arrow-btn {
				right:15px !important;
			}
		}
	}

	@media only screen and (max-width: 767px) {
		.tab-control {
			min-height: auto;
		}
	}

	#pai-main-heading {
		font-size: 40px;
		margin: 50px 16px 35px;
		@media (max-width: 767px) {
			font-size: 30px;
		}
	}

	// Data Table styling
	.pai-data {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		width: 100%;
	}
	.pai-data table {
		@media (max-width: 1230px) {
			font-size: 18px;
		}
		@media (max-width: 991px) {
			font-size: 14px;
		}
		@media (max-width: 310px) {
			font-size: 12px;
		}
	}
	.pai-table-container-spacing {
		@media (max-width: 767px) {
			margin-top: 64px;
		}
	}
	.pai-charts-container-spacing {
		@media (max-width: 767px) {
			margin-top: 80px;
		}
	}
	.pai-data table thead {
		background-color: #44748c;
		color: white;
	}

	.tab-content.pai-statistics-table {
		margin-top: 80px;
		padding-right: 36px;
		padding-left: 36px;
		padding-bottom: 36px;
		box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.15);
		@media (max-width: 991px) {
			padding-right: 24px;
			padding-left: 24px;
		}
		@media (max-width: 767px) {
			margin-top: 160px;
		}
		@media (max-width: 460px) {
			padding-right: 0px;
			padding-left: 0px;
		}
	}

	.pai-data table tbody tr:nth-child(odd) {
		background-color: rgba(153, 209, 192, 0.3);
	}

	// Charts styling
	.pai-box:has(.chart) {
		@media (max-width: 460px) {
			padding: 0;
		}
	}
	.pai-statistics-logo,
	.pai-bar-graph-logo {
		width: 160px;
		position: absolute;
		top: -96px;
		left: 50%;
		transform: translateX(-50%);
		@media (max-width: 767px) {
			width: 128px;
			top: -80px;
		}
	}

	h2.pai-statistics-title {
		font-size: 24px;
		color: #44748c;
		margin-top: 50px;
		margin-bottom: 0px;
		text-align: center;
	}

	h2.pai-bar-graph-title {
		font-size: 24px;
		color: #44748c;
		margin-top: 50px;
		margin-bottom: 16px;
		text-align: center;
	}

	.pai-data-highcharts {
		margin-top: 128px;
	}

	// Loading spinner styling
	.spinner-container {
		width: 100%;
		height: 300px;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
		position: relative;
		background-color: #fff;

		.chart-spinner {
			border: 8px solid rgba(0, 0, 0, 0.1);
			border-radius: 50%;
			border-top: 8px solid #44748c;
			width: 50px;
			height: 50px;
			animation: spin 1s linear infinite;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-color: white;
		}
		
		@keyframes spin {
			0% { transform: translate(-50%, -50%) rotate(0deg); }
			100% { transform: translate(-50%, -50%) rotate(360deg); }
		}
	}

	// PDF button styling
	.pai-buttons-container {
		display: flex;
		justify-content: center;
		list-style-type: none;
		font-size: 18px;
		@media (max-width: 767px) {
			font-size: 16px;
		}
		@media (max-width: 767px) {
			font-size: 14px;
		}
		.download-pdf-button {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: #4F738A;
			border-radius: 25px;
			padding: 10px 20px;
			color: #fff;
			transition: background-color .2s, color .2s;
			text-decoration: none;
			width: fit-content;
			margin: 0 auto;
			border-radius: 9999px;
			box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
			@media (max-width: 460px) {
				padding: 8px 16px;
			}
		}
		.back-to-pai-button {
			color: #728fa1;
			background-color: #fff;
			border: 2px solid #fff;
			border-radius: 9999px;
			transition: background-color .2s, color .2s;
		}

		.download-pdf-button:hover {
			color: #fff;
			background-color: #728fa1;
		}
		.back-to-pai-button:hover {
			background-color: #fff;
			border: 2px solid #728fa1;
		}
		.download-pdf-button:active,
		.back-to-pai-button:active {
			transform: scale(0.95);
			transition: transform .1s;
		}

		.download-pdf-button svg {
			margin-left: 10px;
			fill: #fff;
		}
	}
}
