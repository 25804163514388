/* ACF Business Information Block Styling */
.acf_business-information-block {
	margin-top: 32px;
	margin-bottom: 64px;
}

.acf_business-information-block__title {
	color: #4b9f80;
	font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    font-size: 30px;
	line-height: 36px;
	font-weight: 400;
	margin-top: 32px;
    margin-bottom: 18px;
	@media (max-width: 767px) {
		font-size: 30px;
		line-height: 36px;
	}
	@media (max-width: 600px) {
		font-size: 24px;
		line-height: 32px;
	}
}

.acf_business-information-block__images {
    display: flex;
    justify-content: center;
    gap: 8px;
	position: relative;
	z-index: 2;
	@media (max-width: 767px) {
		flex-direction: column;
    	align-items: center;
	}
}

.acf_business-information-block__images img {
    width: 309px;
	height: 192px;
    object-fit: cover;
	@media (max-width: 1230px) {
		width: 240px;
    	height: 160px;
	}
	@media (max-width: 991px) {
		width: 224px;
    	height: 144px;
	}
	@media (max-width: 767px) {
		width: 560px;
    	height: 352px;
	}
	@media (max-width: 600px) {
		width: 384px;
		height: 240px;
	}
	@media (max-width: 400px) {
		width: 100%;
		height: 160px;
	}
	@media (max-width: 300px) {
		height: 128px;
	}
}

.acf_business-information-block__rows {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(2, 1fr);
	margin-top: -64px;
	@media (max-width: 991px) {
		margin-left: -64px;
		margin-right: -64px;
	}
	@media (max-width: 600px) {
        grid-template-columns: 1fr;
        margin-left: -15px;
		margin-right: -15px;
    }
}

.acf_business-information-block__row:nth-child(1),
.acf_business-information-block__row:nth-child(4),
.acf_business-information-block__row:nth-child(6),
.acf_business-information-block__row:nth-child(8),
.acf_business-information-block__row:nth-child(even) {
	padding-top: 56px;
	padding-right: 96px;
	padding-bottom: 56px;
    padding-left: 112px;
	@media (max-width: 1230px) {
		padding-left: 96px;
	}
	@media (max-width: 991px) {
		padding-top: 48px;
		padding-bottom: 48px;
		padding-left: 80px;
	}
	@media (max-width: 767px) {
		grid-column: span 2;
		padding-right: 96px;
		padding-left: 96px;
	}
	@media (max-width: 600px) {
		padding-right: 64px;
		padding-left: 64px;
	}
	@media (max-width: 400px) {
		padding: 36px 32px;
	}
}

.acf_business-information-block__row:nth-child(2),
.acf_business-information-block__row:nth-child(5),
.acf_business-information-block__row:nth-child(7),
.acf_business-information-block__row:nth-child(9),
.acf_business-information-block__row:nth-child(odd) {
	padding-top: 56px;
	padding-right: 112px;
	padding-bottom: 56px;
    padding-left: 64px;
	@media (max-width: 1230px) {
		padding-right: 96px;
	}
	@media (max-width: 991px) {
		padding-top: 48px;
		padding-bottom: 48px;
		padding-right: 80px;
	}
	@media (max-width: 767px) {
		grid-column: span 2;
		padding-right: 96px;
		padding-left: 96px;
	}
	@media (max-width: 600px) {
		padding-right: 64px;
		padding-left: 64px;
	}
	@media (max-width: 400px) {
		padding: 36px 32px;
	}
}

.acf_business-information-block__row:nth-child(1),
.acf_business-information-block__row:nth-child(2) {
	padding-top: 128px;
	padding-bottom: 48px;
	@media (max-width: 767px) {
		padding-right: 96px;
		padding-left: 96px;
	}
	@media (max-width: 600px) {
		padding-right: 64px;
		padding-left: 64px;
	}
}

.acf_business-information-block__row:nth-child(2) {
	padding-right: 112px;
	padding-left: 64px;
	@media (max-width: 1230px) {
		padding-right: 96px;
	}
	@media (max-width: 991px) {
		padding-right: 80px;
	}
	@media (max-width: 767px) {
		padding-top: 48px;
		padding-right: 96px;
		padding-left: 96px;
	}
	@media (max-width: 600px) {
		padding-right: 64px;
		padding-left: 64px;
	}
	@media (max-width: 400px) {
		padding: 36px 32px;
	}
}

.acf_business-information-block__row:nth-child(1) {
	background-color: #99d2c0;
	padding-left: 112px;
	padding-right: 96px;
	
	@media (max-width: 1230px) {
		padding-left: 96px;
	}
	@media (max-width: 991px) {
		padding-left: 80px;
	}
	@media (max-width: 767px) {
		padding-right: 96px;
		padding-left: 96px;
	}
	@media (max-width: 600px) {
		padding-right: 64px;
		padding-left: 64px;
	}
	@media (max-width: 400px) {
		padding: 112px 32px 36px 32px;
	}
	.acf_business-information-block__row-text {
		color: #fff;
		font-size: 30px;
		line-height: 38px;
		@media (max-width: 1230px) {
			font-size: 28px;
		}
		@media (max-width: 400px) {
			font-size: 24px;
			line-height: 32px;
		}
	}
}

.acf_business-information-block__rows > :nth-last-child(-n+2) {
	padding-bottom: 128px;
	@media (max-width: 400px) {
		padding-bottom: 112px;
	}
}


.acf_business-information-block__rows > :nth-last-child(2) {
	@media (max-width: 767px) {
		padding-bottom: 48px;
	}
	@media (max-width: 400px) {
		padding-bottom: 36px;
	}
}

.acf_business-information-block__row:nth-child(3) {
    grid-column: span 2;
	padding-top: 56px;
	padding-bottom: 56px;
	padding-right: 112px;
    padding-left: 112px;
	@media (max-width: 1230px) {
		padding-right: 96px;
		padding-left: 96px;
	}
	@media (max-width: 991px) {
		padding-right: 80px;
		padding-left: 80px;
	}
	@media (max-width: 767px) {
		padding: 48px 96px;
	}
	@media (max-width: 600px) {
		padding-right: 64px;
		padding-left: 64px;
	}
	@media (max-width: 400px) {
		padding: 36px 32px;
	}
}

.acf_business-information-block__row {
    background-color: #efefef;
    color: #fff;
}

.acf_business-information-block__row-title {
	color: #4F738A;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 18px;
	line-height: 28px;
	font-weight: 700;
	@media (max-width: 400px) {
		font-size: 16px;
		line-height: 24px;
	}
}

.acf_business-information-block__row-text {
	font-size: 22px;
	line-height: 32px;
	font-weight: 400;
	@media (max-width: 1230px) {
		font-size: 20px;
	}
	@media (max-width: 400px) {
		font-size: 18px;
		line-height: 28px;
	}
}

.acf_business-information-block__price-boxes {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 32px;
	position: relative;
	margin-top: -64px;
	@media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
    }
}

.acf_business-information-block__price-box {
    background-color: #00721A;
    color: #fff;
    width: 309px;
	height: 144px;
	padding-right: 48px;
	padding-left: 56px;
	display: flex;
    flex-direction: column;
    justify-content: center;
	@media (max-width: 1230px) {
		width: 240px;
    	height: 128px;
		padding-right: 40px;
		padding-left: 40px;
	}
	@media (max-width: 991px) {
		width: 224px;
	}
	@media (max-width: 767px) {
		width: 560px;
		height: 192px;
	}
	@media (max-width: 600px) {
		width: 384px;
		height: 144px;
	}
	@media (max-width: 400px) {
		width: 100%;
	}
	@media (max-width: 300px) {
		font-size: 18px;
		padding-right: 16px;
		padding-left: 16px;
	}
}

.acf_business-information-block__price-box-title {
	color: white;
	font-size: 20px;
	line-height: 28px;
	font-weight: 300;
	margin-bottom: 0;
	@media (max-width: 1230px) {
		font-size: 18px;
	}
	@media (max-width: 767px) {
		font-size: 24px;
		line-height: 32px;
		margin-bottom: 8px;
		text-align: center;
	}
	@media (max-width: 600px) {
		font-size: 20px;
		line-height: 28px;
	}
	@media (max-width: 300px) {
		font-size: 18px;
	}
}

.acf_business-information-block__price-box-text {
	font-family: Arial, Helvetica, sans-serif;
	color: white;
	font-size: 36px;
	line-height: 40px;
	font-weight: 700;
	margin-bottom: 0;
	@media (max-width: 1230px) {
		font-size: 30px;
		line-height: 36px;
	}
	@media (max-width: 767px) {
		font-size: 48px;
		line-height: 40px;
		text-align: center;
	}
	@media (max-width: 600px) {
		font-size: 36px;
		line-height: 40px;
	}
	@media (max-width: 300px) {
		font-size: 30px;
		line-height: 36px;
	}
}

.acf_business-information-block__url-box {
	background-color: #00721A;
	font-size: 14px;
	line-height: 20px;
    text-align: center;
    margin-top: 32px;
	margin-left: auto;
	margin-right: auto;
	width: fit-content;
	padding: 10px 20px;
	@media (max-width: 767px) {
		font-size: 18px;
		line-height: 28px;
		padding: 8px 24px;
	}
	@media (max-width: 600px) {
		font-size: 16px;
		line-height: 24px;
	}
	@media (max-width: 400px) {
		font-size: 14px;
		line-height: 20px;
		width: 100%;
	}
	@media (max-width: 300px) {
		font-size: 12px;
		line-height: 16px;
	}
}

.acf_business-information-block__url-box a {
    color: #fff;
    text-decoration: none;
}

.acf_business-information-block__url-box a:hover {
    color: #eeeeee;
    text-decoration: underline;
}

.acf_business-information-block__url-box a:active {
    color: #bdbdbd;
}
