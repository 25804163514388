.ctas {
	.cta {
		position: relative;
		img {
			height: 300px;
			width: 100%;
			object-fit: cover;
			@media (max-width: 1230px) {
				height: 250px;
			}
			@media (max-width: 991px) {
				height: 200px;
			}
			@media (max-width: 767px) {
				height: 250px;
			}
			@media (max-width: 480px) {
				height: 200px;
			}
		}
		.cta-message {
			background: rgba(255,255,255,.9);
			padding: 15px 30px;
			position: absolute;
			right: 25px;
			text-align: right;
			top: 25px;
			width: auto;
			p {
				color: $lightGrey;
				font-family: $headingFont;
				font-size: 32px;
				line-height: 1.25;
				margin-bottom: 0;
				span {
					color: $greenSecondary;
					font-weight: 700;
				}
			}
		}
		a.button-link {
			border-color: $white;
			bottom: 25px;
			color: $white;
			height: auto;
			padding: 15px 30px 15px 15px;
			position: absolute;
			right: 25px;
			svg path {
				fill: $white;
				@include transition-no-touch(fill ease $animationSpeed);
			}
			@include touch-hover() {
				background: $white;
				color: $greenSecondary;
				text-decoration: none !important;
				svg path { fill: $greenSecondary; }
			}
		}
		a.button-link-svg { padding: 15px 45px 15px 25px; }
	}
	.double-item-row {
		margin-top: 16px;
		margin-bottom: 16px;
	}
	.single-item-row {
		margin-top: 16px;
		margin-bottom: 16px;
		.cta {
			img {
				@media (min-width: 1230px) {
					height: auto;
				}
			}
			.cta-message {
				@media (min-width: 1230px) {
					top: 283px;
				}
				@media (min-width: 767px) {
					right: 0;
					left: 480px;
				}
			}
		}
	}
}

.full-width-ctas {
	margin-top: 90px;
	.cta {
		background: $greenSecondary;
		position: relative;
		.cta-message {
			padding: 20px 30px 20px 70px;
			position: absolute;
			right: 0;
			top: 0;
			width: 320px;
			p.title {
				font-family: $headingFont;
				font-weight: 700;
				font-size: 32px;
				line-height: 1.1875;
				margin-bottom: 15px;
			}
			p {
				color: $white;
				text-align: right;
			}
		}
		a.button-link {
			border-color: $white;
			bottom: 20px;
			color: $white;
			padding: 15px 30px 15px 15px;
			position: absolute;
			right: 30px;
			svg path { fill: $white; }
			@include transition-no-touch(all ease $animationSpeed);
			@include touch-hover() {
				background: $white;
				color: $greenSecondary;
				text-decoration: none !important;
				svg path { fill: $greenSecondary; }
			}
		}
		a.button-link-svg { padding: 15px 45px 15px 25px; }
	}
}

#signmaster_cta {
	.cta-message p span { color: $signmasterSecondary; }
	a.button-link {
		@include touch-hover() {
			color: $signmasterSecondary;
			svg path { fill: $signmasterSecondary; }
		}
	}
}

#learn_more_cta {
	.cta-message p span { color: $paiBlue; }
	a.button-link {
		@include touch-hover() {
			color: $paiBlue;
			svg path { fill: $paiBlue; }
		}
	}
}

#local_operator_cta {
	.form-wrapper {
		bottom: 25px;
		position: absolute;
		right: 25px;
		form {
			margin-bottom: 0;
			input[type=text] {
				margin-bottom: -5px;
				width: 180px;
			}
			input[type=submit] { float: right; }
		}
	}
}
.operatorSubmitHolder {
	float: right;
	height: 49px;
	margin-top: 10px;
	position: relative;
	width: 142.16px;
	input {
		@include touch-hover() {
			background: white;
			color: #4b9f80;
			text-decoration: none !important;
			svg path { fill: #4b9f80; }
		}
	}
	svg {
		height: 15px;
		margin-left: 15px;
		position: absolute;
		right: 10px;
		top: 25px;
		transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		width: 15px;
		path {
			fill: #fff;
			@include transition-no-touch(all ease $animationSpeed);
		}
	}
	@include touch-hover() {
		input {
			background: white;
			color: #4b9f80;
		}
		svg path { fill: #4b9f80; }
	}
}
