.slider-wrapper {
	&:after{
		clear:both;
	}
	.owl-carousel {
		overflow: hidden;
	  	position: relative;
	  	margin: auto;
	  	.post-card {
	  		height: 270px;
	  		margin-bottom: 0;
			.post-detail {
				width: 100%;
				p.type { font-size: 12px; }
				p.name { font-size: 16px; }
				p.date {
					font-size: 10px;
					right: 38px;
				}
			}
		}
	}
	.controls {
		height: 30px;
		margin-top: 15px;
		position: relative;
		.prev, .next {
			cursor: pointer;
			position: absolute;
			svg {
				height: 30px;
				width: 30px;
				path {
					fill: $greenSecondary;
					@include transition-no-touch(fill ease $animationSpeed);
				}
			}
			@include transition-no-touch(opacity ease $animationSpeed);
			@include touch-hover() {
				svg path { fill: $greenPrimary; }
			}
		}
		.prev { left: 0; }
		.next { right: 0; }
	}
	a.button-link {
		display: block;
		margin-bottom: 0;
		width: 220px;
	}
}

.owl-carousel .owl-item { padding: 0 15px; }

.controlsBlue{
	a svg path{
		fill: $paiBlue !important;
	}
}

.pai-post .tags #view_prev_years{
	border-color: $paiBlue;
	color: $paiBlue;
	margin: 30px auto;
	background:white;
	svg path { fill: $paiBlue; }
	@include touch-hover() {
		background: #44748c;
		color: #fff;
		text-decoration: none !important;
        svg path { fill: $white; }
    }
}
.pai-post .tags #view_prev_years.active{
	background: #44748c;
	color: #fff;
	svg path{ fill: $white; }
}