a {
	color: $greenSecondary;
	cursor: pointer;
  	text-decoration: none;
  	outline: 0;
    &:focus, &:active, &:hover {
        outline: 0;
        text-decoration: none;
    }
  	@include transition-no-touch(color ease $animationSpeed);
    @include touch-hover() { text-decoration: none; }
}


/* Mobile device & iOS phone number styling override */
a[href^="tel"], a[x-apple-data-detectors] {
    color: inherit !important;
    font-family: inherit !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    text-decoration: none;
}
