.post-card {
	height: 400px;
	overflow: hidden;
	position: relative;
	img {
		height: auto;
		width: 100%;
	}
	.post-detail {
		background: rgba(255,255,255,.9);
		bottom: 30px;
		height: 125px;
		position: absolute;
		right: 0;
		@include transition-no-touch(background ease $animationSpeed);
		width: 470px;
		.post-detail-content { padding: 15px 38px; }
		p {
			line-height: 1;
			text-align: right;
		}
		p.type, p.date {
			color: $greenSecondary;
			font-family: $headingFont;
			font-weight: 700;
			text-transform: uppercase;
			@include transition-no-touch(color ease $animationSpeed);
		}
		p.type {
			font-size: 16px;
			margin-bottom: 5px;
		}
		p.name {
			font-family: $headingFont;
			font-size: 20px;
			line-height: 1.25;
			@include transition-no-touch(color ease $animationSpeed);
		}
		p.date {
			bottom: 5px;
			font-size: 12px;
			line-height: 1;
			padding-top: 20px;
			position: absolute;
			right: 38px;
		}
		ul.share {
			bottom: 5px;
			left: 15px;
			list-style: none;
			margin: 0;
			padding-left: 0;
			position: absolute;
			li {
				display: inline-block;
				margin-right: 10px;
				svg {
					height: 15px;
					width: 15px;
					path {
						fill: $greenSecondary;
						@include transition-no-touch(fill ease $animationSpeed);
					}
				}
				a#facebook {
					@include touch-hover() {
						svg path { fill: $facebook; }
					}
				}
				a#google_plus {
					@include touch-hover() {
						svg path { fill: $googlePlus; }
					}
				}
				a#twitter {
					@include touch-hover() {
						svg path { fill: $twitter; }
					}
				}
				a#instagram {
					@include touch-hover() {
						svg path { fill: $instagramPurple; }
					}
				}
			}
		}
		@include touch-hover() {
			background: rgba(76,159,128,.9);
			p.type, p.name, p.date {
				color: $white;
				text-decoration: none !important;
			}
			ul.share li svg path { fill: $white; }
		}
		a.post-card-inner {
			@include touch-hover() {
				p.type, p.name, p.date { text-decoration: none !important; }
			}
		}
	}
}

.post-card-fw {
	.post-detail {
		width: auto;
		p.type {
			font-size: 20px;
			margin-bottom: 10px;
		}
		p.name {
			font-size: 25px;
			line-height: 1;
		}
		p.date { font-size: 12px; }
	}
}

.sales-post-card .post-detail {
	p.type, p.date { color: $salesBlue; }
	ul.share li svg path { fill: $salesBlue; }
	@include touch-hover() { background: rgba(68,116,128,.9); }
}

.lettings-post-card .post-detail {
	p.type, p.date { color: $lettingsBlue; }
	ul.share li svg path { fill: $lettingsBlue; }
	@include touch-hover() { background: rgba(84,128,163,.9); }
}

.property-post-card .post-detail {
	p.type, p.date { color: $paiBlue; }
	ul.share li svg path { fill: $paiBlue; }
	@include touch-hover() { background: rgba(68,116,140,.9); }
}

.pai-posts .post-card .post-detail p.name,
.pai-post-card {
	.post-detail {
		p.date, p.type { color: $paiBlue; }
		p.name { text-transform: capitalize; }
		ul.share li svg path { fill: $paiBlue; }
		@include touch-hover() { background: rgba(68,116,140,.9) !important; }
	}
}
