/*Mobile Form Styles*/
.page-template-page-signmaster .quote-form-mobile { background: rgba(84, 128, 177, .9) !important; }

.page-template-page-business_management .quote-form-mobile { background: rgba(84, 128, 177, .9) !important; }

.quote-form-mobile {
    display: none;
    .quote-form-inner {
        padding-left: 25px;
        padding-right: 25px;
    }
    .form-toggle {
        height: 80px;
        display: block;
        font-size: 20px;
        margin-top: 20px;
        position: relative;
        text-transform: uppercase;
        transition: margin-top ease $animationSpeed;
        a { color: #fff; }
        #close {
            padding-left: 53px;
            cursor: pointer;
            position: absolute;
            top: 0;
            @include transition-no-touch(all ease $animationSpeed);
            svg {
                height: 40px;
                left: 0;
                margin-right: 10px;
                position: absolute;
                top: -2px;
                width: 40px;
                path {
                    fill: #fff;
                    @include transition-no-touch(fill ease $animationSpeed);
                }
            }
        }
        span.handwritten {
            position: absolute;
            right: 50px !important;
            margin-right: -40px;
            color: #fff;
            float: right;
            font-size: 28px;
            line-height: 1.25;
            margin-top: -20px;
            opacity: 0;
            text-align: right;
            text-transform: none;
            transition: opacity ease $animationSpeed;
        }
        &.active #close { left: 25px; }
    }
}

.mobile_form_bg { background: #4c9f80; }

.page-template-page-signmaster.mobile_form_bg,
.page-template-page-business_management.mobile_form_bg { background: #5480b1 !important; }

/*Desktop Form Styles*/
.page-template-page-signmaster .quote-form-desktop,
.page-template-page-business_management .quote-form-desktop { background: rgb(84, 128, 177); }

.quote-form-desktop {
    left: -500px;
    width: 450px;
    position: fixed;
    top: 0px;
    background: #4b9f80;
    transition: all .3s ease;
    z-index: 4;
    padding-bottom: 20px;
    .quote-form-inner {
        padding-left: 25px;
        padding-right: 25px;
    }
    .form-toggle {
        height: 80px;
        margin-bottom: 20px;
        display: block;
        font-size: 20px;
        margin-top: 55px;
        position: relative;
        text-transform: uppercase;
        transition: margin-top ease $animationSpeed;
        a { color: #fff; }
        #close {
            padding-left: 53px;
            cursor: pointer;
            position: absolute;
            top: 0;
            @include transition-no-touch(all ease $animationSpeed);
            @include touch-hover() {
                color: $greenSupporting;
                svg {
                    path, rect { fill: $greenSupporting; }
                }
            }
            svg {
                height: 40px;
                left: 0;
                margin-right: 10px;
                position: absolute;
                top: -2px;
                width: 40px;
                path {
                    fill: #fff;
                    @include transition-no-touch(fill ease $animationSpeed);
                }
            }
        }
        span.handwritten {
            position: absolute;
            right: 50px !important;
            margin-right: -40px;
            color: #fff;
            float: right;
            font-size: 28px;
            line-height: 1.25;
            margin-top: -10px;
            opacity: 0;
            text-align: right;
            text-transform: none;
            transition: opacity ease $animationSpeed;
        }
        &.active #close { left: 25px; }
    }
    form textarea { height: 100px !important; }
    input { margin-bottom: 10px; }
    input[type="text"] {
        height: 38px !important;
        padding: 7px 10px !important;
        font-size: 14px !important;
        font-weight: 400 !important;
    }
    textarea {
        margin-bottom: 10px !important;
        padding: 7px 10px !important;
        font-size: 14px !important;
    }
    div.wpcf7-response-output {
        margin: 1em .5em 0em .5em !important;
        padding: 0.2em 1em;
    }
    &.active { left: 0px; }
	span.handwritten.thank-you {
		color: white;
		font-size: 28px;
		position: absolute;
		bottom: 36px;
    	left: 42px;
	}
}
