/* Default Template */
body.page-template-default {
	.intro {
		margin-bottom: 60px;
		p { text-align: center; }
		.two-column-text p { text-align: left; }
		a {
			color: $greenSecondary;
			@include touch-hover() { color: $greenSupporting; }
		}
	}
}

/* About us page */
body.page-id-8 {
	.flexible-content { margin-bottom: 20px; }
	.cards { margin-bottom: 70px; }
	.ctas { margin-bottom: 10px; }
}

/* Services page */
body.page-id-4294 {
	.cards { margin-bottom: 90px; }
}

/* Residential Services page */
body.page-id-4296 {
	.flexible-content {
		margin-bottom: 70px;
		.text-image-row .form-row { height: 100%; }
	}
}

/* Commercial & National Services page */
body.page-id-4298, body.page-id-4300 {
	.flexible-content .text-image-row {
		margin-bottom: 110px;
		&:last-child { margin-bottom: 50px; }
		a.button-link { margin-top: 20px; }
	}
}

/* Find an Operator page */
body.page-id-310 {
	.banner {
		max-height: calc(100vh - 149px);
		.banner-title {
			background: rgba(76, 159, 128, 0.9);
			text-align: right;
			h1, p { color: $white; }
			h1 {
				font-size: 35px;
				margin: 15px 0 25px;
				span {
					color: $white;
					font-weight: 700;
				}
			}
			p {
				font-size: 25px;
				line-height: 1.2;
				margin-bottom: 10px;
				span { color: $white; }
			}
		}
	}
	.operatorSubmitHolderPage {
		position: relative;
	}
	.operatorSubmitHolderPage svg {
		height: 15px;
		margin-left: 15px;
		position: absolute;
		right: 10px;
		top: 25px;
		transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		width: 15px;
	}
	.operatorSubmitHolderPage svg path {
		fill: #fff;
		@include transition-no-touch(all ease $animationSpeed);
	}
	.operatorSubmit {
		@include touch-hover() {
			& ~ .operatorArrow path { fill: #4b9f80; }
		}
	}
}

/* Single Area pages */
body.single-area {
	#map {
		height: 544px;
		width: 100%;
	}
	.intro {
		margin-bottom: 80px;
		img {
			display: block;
			margin-left: auto;
			margin-right:auto;
			margin-bottom:35px;
			margin-top:0px;
		}
		a.button-link {
			position: absolute;
    		right: 15px;
			top: 48px;
			z-index: 1;
			@include transition-no-touch(all ease $animationSpeed);
			@include touch-hover() {
				background: $greenSecondary !important;
				color: $white !important;
				text-decoration: none !important;
				svg path { fill: $white !important; }
			}
		}
		h2 { margin-top: 50px; }
	}
	.region-map {
		margin-bottom: 30px;
		position: relative;
		.areas-panel-wrapper {
			position: absolute;
			top: 30px;
			width: 100%;
			.areas-panel {
				background: rgba(76, 159, 128, 0.9);
				color: $white;
				font-family: $headingFont;
				font-size: 32px;
				font-weight: 300;
				padding: 20px 60px;
				p {
					color: $white;
					line-height: 1.4;
					margin-bottom: 15px;
					text-align: center;
				}
				ul {
					list-style: none;
					margin: 0;
					padding-left: 0;
					width: 100%;
					li {
						color: $white;
						display: inline-block;
						line-height: 1;
						padding: 5px 15px;
						text-align: center;
						width: 49%;
					}
				}
			}
		}
	}
}

.property-title{
	span{ color: $paiBlue !important;}
}
.property-year{
	color: $paiBlue !important;
}
.property-control{
	svg{
		path{
			fill:$paiBlue !important;
		}
	}
}
.property-btn{
	border: 2px solid #44748c !important;
	color:$paiBlue !important;
	svg{
		path{
			fill:$paiBlue !important;
		}
	}
	@include touch-hover() {
		border: 2px solid #44748c !important;
		background: $paiBlue !important;
		color: white !important;
		svg path { fill:white !important; }
	}
}

/* Property Activity Index landing page */
body.page-id-77 {
	.banner .banner-title h1 span { color: $signmasterSecondary; }
	.intro {
		img {
			display: block;
			margin: 50px auto 35px;
			max-width: 100%;
			width: 320px;
		}
		a.button-link {
			border-color: $paiBlue;
			color: $paiBlue;
			height: 49px;
			position: absolute;
	    	right: 15px;
			top: 60px;
			svg path { fill: $paiBlue; }
			@include touch-hover() {
				background: $paiBlue;
				color: $white;
				text-decoration: none !important;
				svg path { fill: $white; }
			}
		}
	}
	.pai-posts {
		margin-bottom: 80px;
		a.button-link {
			border-color: $paiBlue;
			color: $paiBlue;
			margin: 30px auto;
			@include touch-hover() {
				background: $paiBlue;
				color: $white;
				text-decoration: none !important;
			}
		}
		a#view_prev_report {
			display: block;
			@include transition-no-touch(all ease $animationSpeed);
			width: 306px;
			svg path {
				fill: $paiBlue;
				@include transition-no-touch(fill ease $animationSpeed);
			}
			@include touch-hover() {
				background: $paiBlue;
				color: $white;
				text-decoration: none !important;
				svg path { fill: $white; }
			}
			&.active {
				background: $paiBlue;
				color: $white;
				svg path { fill: $white; }
			}
		}
		.slider-wrapper {
			display: none;
			.controls {
				.prev, .next {
					svg {
						height: 30px;
						width: 30px;
						path {
							fill: $paiBlue;
							@include transition-no-touch(fill ease $animationSpeed);
						}
					}
					@include touch-hover() {
						svg path { fill: $lightBlueSupporting; }
					}
				}
			}
			a.button-link-svg {
				display: block;
				width: 185px;
				svg path {
					fill: $paiBlue;
					@include transition-no-touch(fill ease $animationSpeed);
				}
				@include touch-hover() { svg path { fill: $white; } }
			}
			&.active { display: block; }
		}
	}
}

/* Single Property Activity Index page */
body.single-pai {
	.banner .banner-title p span { color: $paiBlue; }
	h1, .tags p { color: $paiBlue; }
	h1 {
		font-size: 32px;
		margin: 50px 120px 35px;
		text-align: center;
	}
	table {
		border: 0;
		border-color: transparent;
		margin: 25px 0;
		width: 100%;
	}
	.tags ul li {
		background: $paiBlue;
		@include touch-hover() { background: $blueSupporting; }
	}
	.pagination {
		.controls {
			margin-bottom: 30px;
			.next, .prev {
				svg path { fill: $paiBlue; }
				a { color: $paiBlue; }
				@include touch-hover() {
					a { color: $blueSupporting; }
					svg path { fill: $blueSupporting; }
				}
			}
		}
		a.button-link-svg {
			border-color: $paiBlue;
			color: $paiBlue;
			margin-top: 20px;
			padding: 15px 25px 15px 45px;
			width: 350px;
			svg {
				left: 15px;
				margin-left: 0;
				path { fill: $paiBlue; }
			}
			@include touch-hover() {
				background: $paiBlue;
				color: $white;
				svg path { fill: $white; }
			}
		}
	}
}

/* News Hub page */
body.page-id-559 {
	.intro { margin-bottom: 20px; }
	.categories { margin-bottom: 60px; }
	h3 {
		color: $greenSecondary;
		font-size: 36px;
	}
	.spotlight {
		margin-bottom: 50px;
		h3 { text-align:right;}
	}
	.news-posts {
		h3 { text-align:right; }
		margin-bottom: 40px;
		.slider-wrapper {
			margin-top: 30px;
			.controls {
				.prev, .next {
					svg path {
						fill: $greenSecondary;
						@include transition-no-touch(fill ease $animationSpeed);
					}
					@include touch-hover() {
						svg path { fill: $greenSupporting; }
					}
				}
			}
		}
		a.button-link-svg {
			display: block;
			margin: 30px auto;
			width: 185px;
			@include touch-hover() {
				background: $greenSecondary;
				color: $white;
			}
		}
	}
}


/* Single News Posts */
body.single-post {
	.main-post-title > h1 {
		margin: 36px 0 40px;
    	text-align: center;
	}
	.intro img {
		height: auto;
		width: 100%;
	}
	.twocolumn > :first-child { margin-top: 0; }
	.twocolumn, .two-column-text {
		img {
			display: block;
			margin: 0 auto;
			width: auto;
		}
	}
	.pagination {
		.controls {
			.prev svg, .next svg {
				height: 20px;
				width: 20px;
			}
			.prev svg { margin-right: 5px; }
			.next svg { margin-left: 5px; }
		}
		a.button-link-svg {
			padding: 15px 25px 15px 45px;
			svg {
				left: 15px;
				margin-left: 0;
			}
		}
	}
	div#main.pai-post {
		h1, h2, h3, .banner .banner-title h1 span, .banner .banner-title p span, .tags p, .pagination .controls .next a, .pagination .controls .prev a { color: $paiBlue; }
		.slider-wrapper, .pagination {
			.controls .next svg path, .controls .prev svg path { fill: $paiBlue; }
		}
		a.button-link-svg {
			border-color: $paiBlue;
			color: $paiBlue;
			svg path {
				fill: $paiBlue;
				@include transition-no-touch(fill ease $animationSpeed);
			}
			@include touch-hover() {
				background: $paiBlue;
				color: $white;
				text-decoration: none !important;
				svg path { fill: $white; }
			}
		}
		.tags ul li {
			background: $paiBlue;
			@include touch-hover() { background: $blueSupporting; }
		}
		.pagination .controls .next a:hover, .pagination .controls .prev a:hover { color: $blueSupporting; }
	}
}

/* News Archive Page */
body.page-id-4587 {
	.intro p { text-align: center; }
	.categories { margin-bottom: 20px; }
	h3 {
		margin-bottom: 25px;
		text-align: left;
	}
	.news-posts {
		margin-bottom: 60px;
		.slider-wrapper .controls {
			height: 40px;
			margin-bottom: 20px;
			svg path {
				fill: $greenSecondary;
				@include transition-no-touch(fill ease $animationSpeed);
			}
			a {
				@include touch-hover() {
					svg path { fill: $greenSupporting; }
				}
			}
		}
		#view_prev_years {
			display: block;
			margin: 0 auto;
			width: 265px;
			@include touch-hover() {
				background: $greenSecondary;
				color: $white;
				text-decoration: none !important;
				svg path { fill: $white; }
			}
			&.active {
				background: $greenSecondary;
				color: $white;
				text-decoration: none !important;
				svg path { fill: $white; }
			}
		}
		div#previous_years { display: none; }
	}
}

/* Archive, Category & Tag pages */
body.archive, body.category, body.tag {
	h1 {
		font-size: 32px;
		margin: 50px 120px 35px;
		text-align: center;
	}
	.intro { margin-bottom: 20px; }
	h2 { margin: 50px 0 10px; }
	.post-card {
  		height: 270px;
		.post-detail {
			width: 100%;
			p.type { font-size: 12px; }
			p.name { font-size: 16px; }
			p.date {
				font-size: 10px;
				right: 20px;
			}
		}
	}
	#view_prev_years {
		display: block;
		margin: 0 auto;
		width: 265px;
		@include touch-hover() {
			background: $greenSecondary;
			color: $white;
			text-decoration: none !important;
			svg path { fill: $white; }
		}
		&.active {
			background: $greenSecondary;
			color: $white;
			text-decoration: none !important;
			svg path { fill: $white; }
		}
	}
}

/* PAI Archive page */
body.post-type-archive-pai {
	.intro p { text-align: center; }
	h1, h2, h3 { color: $paiBlue; text-align: center; }
	.slider-wrapper .controls .next, .slider-wrapper .controls .prev {
		svg path { fill: $paiBlue; }
	}
	#view_prev_years {
		border-color: $paiBlue;
		color: $paiBlue;
		svg path { fill: $paiBlue; }
		@include touch-hover() {
			background: $paiBlue;
			color: $white;
			text-decoration: none !important;
			svg path { fill: $white; }
		}
		&.active {
			background: $paiBlue;
			color: $white;
			text-decoration: none !important;
			svg path { fill: $white; }
		}
	}
}

/* Franchisee Home */
body.page-id-26 {
	.intro { margin-bottom: 80px; }
	.cards .signmaster-card .card-detail img { margin-top: 8px; }
}

/* Why Agency page */
body.page-id-24 {
	.pageTitle { margin-bottom: 65px; }
	.flexible-content {
		margin-bottom: 80px;
		h2 {
			margin: 0 0 60px;
			text-align: center;
		}
		.sr-left, .sr-right {
			ul {
				margin-bottom: 0;
				li { margin-bottom: 45px; color:$lightGrey; }
			}
		}
		.text-image-row h3 { font-size: 32px; }
	}
}

/* Franchise Package page */
body.page-id-30 {
	.flexible-content {
		.sr-left ul, .sr-right ul {
			margin: 0 0 80px;
			li {
				margin-bottom: 30px;
				h3 {
					font-size: 32px;
					line-height: 1;
					margin-bottom: 10px;
				}
			}
		}
		.twocolumn { margin-bottom: 60px; }
		.text-image-row {
			h4 { font-size: 32px; }
			p { margin-bottom: 40px; }
			li{ color:#676767; }
		}
	}
}

/* Franchise Opportunities page */
body.page-id-38 {
	.flexible-content {
		margin-bottom: 80px;
		.text-image-row .form-trigger { margin-top: 15px; }
	}
}

/* Success Stories page */
body.page-id-4531 {
	h3 {
		margin: 0 0 20px;
	}
	.post-card .post-detail{
		padding: 15px 25px;
	}
	.spotlight {
		h2 {
			margin-top: 0;
			text-align: right;
		}
		.button-link{
			border-color: #fff;
			color: #fff;
			left: 50%;
			padding: 15px;
			margin-top: 20px;
			transform: translateX(-50%);
			margin-bottom: 0px;
			display:none;
		}
	}
	.post-card-fw {
		margin-bottom: 100px;
		.post-detail {
			p.name, p.location {
				font-family: $headingFont;
				font-size: 36px;
				line-height: 1.25;
				margin-bottom: 0;
				@include transition-no-touch(color ease $animationSpeed);
			}
			p.name {
				color: $greenSecondary;
				font-weight: 700;
				margin-bottom: 3px;
			}
			.card-content { display: none; }
		}
		@include touch-hover() {
			.post-detail {
				background: rgba(76, 159, 128, 0.9);
				p.name, p.location { color: $white; }
			}
		}
	}
}

/* Single Case Study Posts */
body.single-success-story {
	.twocolumn > :first-child { margin-top: 0; }
	.pagination a.button-link-svg {
		width: 335px;
		padding: 15px 25px 15px 45px;
		svg {
			left: 15px;
			margin-left: 0;
		}
	}
}


/* Apply Online page */
body.page-id-40 {
	.intro { margin-bottom: 0; }
}

/* Property Activity Index FAQs page */
body.page-id-1837 {
	.banner .banner-title h1 span { color: $paiBlue; }
	.intro {
		margin: 0px 0 20px;
		text-align: center;
		img{
			display: block;
			margin: 50px auto 35px;
			max-width: 100%;
			width: 320px;
		}
	}
	.faqs {
		h2 {
			color: $paiBlue;
			font-size: 32px;
			text-align: center;
		}
		h3 {
			font-size: 26px;
			text-align: left;
		}
		a.button-link {
			border-color: $paiBlue;
			color: $paiBlue;
			float: right;
			margin-bottom: 90px;
			svg path { fill: $paiBlue; }
			@include touch-hover() {
				background: $paiBlue;
				color: $white;
				text-decoration: none !important;
				svg path { fill: $white; }
			}
		}
	}
}

/* Request Our Prospectus page */
body.page-id-4806 {
	.intro {
		margin-bottom: 90px;
		padding-top: 30px;padding-bottom:10px;
		color:white;
		.form-panel {
			padding-bottom: 80px;
			padding-left:25px; padding-right:25px;
			background: rgba(76,159,128,.9);
		    padding-top: 30px;
			p { color: white; }
			.wpcf7{ margin-top:10px; color:black;}
		}
	}
	.download-link{
		display:none;
		text-align:center;
		.button-link{
			margin:0 auto;
			margin-top:20px;
			background:$greenSecondary;
			border:1px solid white;
			color:white;
			path { fill: white; }
			@include touch-hover() {
				background: white;
				color: $greenSecondary;
				text-decoration: none !important;
				path { fill: $greenSecondary; }
			}
		}
	}
	.handwrittenWrapper{ text-align:left;}
}

/* 404 page */
body.error404 {
	.intro {
		margin-top: 90px;
		img {
			height: auto;
			margin-top: -30px;
			position: absolute;
			width: 110px;
		}
		p { padding-left: 140px; }
	}
}

/* Thank You for Your page */
body.page-id-4817 {
	#map {
		height: 1000px;
		width: 100%;
	}
	.map {
		margin-bottom: 60px;
		.form-panel {
			margin-top: 75px;
			form {
				p:empty{ display:none; }
				select{ margin-bottom:15px; }
				position: relative;
				h3 { display: none; }
				p {
					font-size: 28px;
					line-height: 1.4;
					text-align: center;
					padding-top:30px;
					input{
						margin-top: 30px;
					}
				}
			}
		}
	}
}


/*contact map default size*/
.contactMap{
	height: 790px;
	width: 100%;
}
.page-id-22 .contactMap,
.page-id-40 .contactMap{
	height: 1450px !important;
	width: 100%;
}

/*map marker-label position style*/
.map-marker-label{
	position: absolute;
}

#CompListingsMonth{ display:inline-block;}

.chart{ width:100%;}

.carouselPagination{ display:none; }

#main{
	background-image:linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%), linear-gradient(to bottom, rgba(75, 159, 128, 1) 0%, rgba(75, 159, 128, 1) 100%);
	background-clip: content-box, padding-box;
}
body.page-id-32, body.page-id-17{
	#main {
		background-image:linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%), linear-gradient(to bottom, rgba(84, 128, 177, 1) 0%, rgba(84, 128, 177, 1) 100%);
		background-clip: content-box, padding-box;
	}
}


body.page-id-32 #quote_mobile_form_toggle, body.page-id-17 #quote_mobile_form_toggle{
	background: rgba(84, 128, 177, .9);
}

body.page-id-32 #main {
	margin-bottom: 0;
	.testimonials { margin-bottom: 30px; }
}

body.page-template-page-download_prospectus_2{
	div.wpcf7-response-output {
		margin: 0em 0.5em 0em !important;
		padding: 0.2em 1em;
	}
  .banner { overflow: initial; }
	.banner-title-wrapper{
		top:0px;
		position:absolute !important;
		.banner-title{
			background: rgba(76,159,128,.9);
			min-height: 292px;
			min-width: 1450px;
			transition: height ease $animationSpeed;
			h1 {
				color:white;
				font-size:28px; 
				margin-bottom:20px;
				text-align:left;
			}
			.form-holder {
				padding: 30px 25px 30px !important;
				h4 {
					color: white;
					margin-top :0px;
					font-size: 20px;
				}
				width:400px;
			}
		}
	}
	.banner{
		max-height: initial;
		margin-bottom: 44px;
	}
}

/* Privacy Policy page */
body.page-id-3642 {
	.intro { margin-top: 60px; }
	h3 { margin: 40px 0 15px; }
}

/* AgencyQR Logo On All Pages */
body {
	.site-header {
		#agencyqr-logo,
		#agency-keysafe-logo,
		#signmaster_logo {
			float: right;
			margin-top: 48px;
			transition: margin-top ease .3s;
			width: 192px;
			@media (max-width: 1400px) {
				width: 128px;
				margin-top: 56px;
			}
			@media (max-width: 1140px) {
				width: 128px;
			}
		}
		#agency-keysafe-logo {
			margin-top: 54px;
			scale: 1.2;
			@media (max-width: 1400px) {
				margin-top: 60px;
			}	
		}
	}
	.site-footer {
		#footer_green {
			#scroll_top {
				filter: hue-rotate(29deg);
				svg g circle {
					fill: rgba(19, 89, 64, 0.5);
				}
			}
		}
	}
	.site-header.minified {
		#agencyqr-logo,
		#agency-keysafe-logo,
		#signmaster_logo {
			margin-top: 14px;
		}
		#agency-keysafe-logo {
			margin-top: 20px;
		}
	} 
	.introqr { 
		img {
			display: block;
			width: 384px;
			margin: 50px auto 35px;
			@media (max-width: 991px) {
				margin: 50px auto 100px;
			}	
			@media (max-width: 767px) {
				width: 345px;
			}	
			@media (max-width: 479px) {
				width: 270px;
			 }	
		}
		#sm4_form_trigger_1 {
			position: absolute;
			top: 75px;
			right: 0;
			@media (max-width: 1229px) {
				top: 32px;
			}	
			@media (max-width: 991px) {
				top: 124px;
				left: 50%;
				transform: translateX(-50%);
				-ms-transform: translateX(-50%);
				width: 200px;
			}	
			@media (max-width: 767px) {
				width: 186px;
			}	
			@media (max-width: 479px) {
				top: 100px;
			}	
		}
	}
	.intro {
		margin-top: 16px;
	}
	#sm4_logo {
		width: 385px;
		position: relative;
		bottom: 30px;
		@media (max-width: 767px) {
			margin: 0;
			width: 345px;
		}	
		@media (max-width: 479px) {
			width: 270px;
		 }	
	}
	#signmaster_form_1 {
		p.title {
			font-size: 24px;
			font-weight: 700;
			margin-bottom: 0;
			text-transform: uppercase;
		}
	}
	.qr-flex {
		display: flex;
		justify-content: center;
		position: relative;
    	right: 36px;
		@media (max-width: 1100px) {
			visibility: hidden;
		}	
		a {
			img {
				margin-right: 32px;
				margin-left: 32px;

				@media (max-width: 1500px) {
					margin-right: 16px;
					margin-left: 16px;
				}
				@media (max-width: 1140px) {
					margin-right: 10px;
					margin-left: 10px;
				}	
			}
		}
	}
}
