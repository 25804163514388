.slider-wrapper .controls{
    margin-top: 25px;
    height: 30px;
}
.slider-wrapper .controls a svg {
    fill: #4b9f80;
}
#previous_years{ display:none; }
.align-right{ text-align:right; }
#view_prev_years{
    margin: 0 auto;
    display: block;
    width: 250px;
    &.active {
    	background: $greenSecondary;
    	color: $white;
    	svg path { fill: $white; }
    }
}