.privacy-policy-content{
	h2{
		font-size: 2.4rem;
		margin: 2rem 0 1rem !important;
	}
	dl{
		margin: 0;
		padding-left: 3rem;
	}
	ol {
		list-style-type: none;
		counter-reset: item;
		padding: 0;
		margin: 1.5rem 0 1.5rem 3rem;
	}

	ol > li {
		display: table;
		counter-increment: item;
		margin-bottom: 0.6em;
	}

	ol > li:before {
		content: counters(item, ".") ". ";
		display: table-cell;
		padding-right: 0.6em;    
	}

	li ol > li {
		margin: 0;
	}

	li ol > li:before {
		content: counters(item, ".") " ";
	}
	ol:first-of-type{
		margin-left:0px !important;
	}
	p {
		margin: 0 0 2rem;
	}
}