/* ACF Article Cards Block Styling */
.container.acf_article-cards_block {
	margin-top: 100px;
	margin-bottom: 64px;
}

.acf_article-cards_block__article-cards {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 384px));
	gap: 6rem;

	@media (min-width: 1230px) {
		grid-template-columns: repeat(3, 1fr);
	}
	@media (max-width: 1229px) {
		grid-template-columns: repeat(3, 1fr);
		gap: 2rem;
	}
	@media (max-width: 991px) {
		grid-template-columns: repeat(2, 1fr);
		gap: 2rem;
	}
	@media (max-width: 600px) {
		grid-template-columns: repeat(1, 1fr);
	}
}

.acf_article-cards_block__article-card {
	display: block;
	text-decoration: none;
	position: relative;
	border: 1px solid #ddd;
	border-radius: 4px;
	box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
	transition: box-shadow 0.3s ease;
	border: 1px solid #fff;
	padding: 1rem;
}

.acf_article-cards_block__article-card:hover {
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
	border: 1px solid #98d1c023;
	cursor: pointer;
	background-color: #98d1c023;
}

.acf_article-cards_block__header {
	display: flex;
	align-items: flex-start;
}

.acf_article-cards_block__location-pin-icon {
	position: relative;
	top: -21px;
	@media (max-width: 300px) {
		display: none;
	}
}

.acf_article-cards_block__title_container {
	margin-left: 8px;
}

.acf_article-cards_block__area-text {
	color: #707070;
}

.acf_article-cards_block__title {
	color: #4b9f80;
	font-size: 20px;
	margin: 0;
	@media (max-width: 300px) {
		margin-bottom: 8px;
	}
}

.acf_article-cards_block__excerpt {
	padding: 0 24px 8px 24px;
	@media (max-width: 300px) {
		padding: 0 8px 2px 8px;
	}
}