body *:not(
	.quote-form-desktop, 
	.menu-wrapper, #signmaster_form_1, 
	.form-row, 
	.pai-template-default, 
	.pai-template-default *, 
	.page-template-page-faq, 
	.page-template-page-faq *,
	.page-template-page-success-stories,
	.page-template-page-success-stories *
	.page-template-page-charity-php .cards,
	.page-template-page-charity-php .cards *
	.page-template-block-template .container,
	.page-template-block-template .container *) {
    -o-transition-property: none !important;
    -moz-transition-property: none !important;
    -ms-transition-property: none !important;
    -webkit-transition-property: none !important;
    transition-property: none !important;
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
    animation-duration: 0ms !important;
}


body #cardsWrapper.cards, body #cardsWrapper.cards *,
body .cards, body .cards * {
    transition: all ease .1s !important;
}

body #gform_wrapper_19, body #gform_wrapper_19 * {
	transition: all ease .1s !important;
}

.site-header .menu-wrapper,
.form-row {
    transition: all ease .3s !important;
}
