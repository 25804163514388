.statistics {
	margin-bottom: 90px;
	.stat {
		text-align: center;
		img {
			display: block;
			height: 90px;
			margin: 0 auto 20px;
			width: auto;
		}
		span {
			display: inline-block;
			font-family: $headingFont;
			font-size: 55px;
			font-weight: 300;
			line-height: 1;
			margin-bottom: 5px;
		}
		p {
			color: $lightGrey;
			font-family: $bodyFont;
			font-size: 18px;
			line-height: 1.5;
			margin-bottom: 0;
			padding: 0 20px;
		}
	}
	.red-stat {
		svg {
			path, rect { fill: $redPrimary; }
		}
		span { color: $redPrimary; }
	}
	.green-stat {
		svg {
			path, rect { fill: $greenPrimary; }
		}
		span { color: $greenPrimary; }
	}
}