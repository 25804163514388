table, .table {
    background-color: transparent;
    border-collapse: collapse;
    margin: 2rem 0;
    max-width: 100%;
    width: 100%;
    thead th {
        vertical-align: bottom;
        border-bottom: 2px solid #dee2e6;
    }
    td, th {
        border-top: 1px solid #dee2e6;
        padding: .75rem;
        vertical-align: top;
        line-height: 1.5;
    }
    th { text-align: inherit; }
}

.table-striped tbody tr:nth-of-type(odd) { background-color: rgba(0,0,0,.05); }