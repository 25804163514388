.gform_wrapper.gravity-theme {
	/* Set a consistent appearance for select elements */
	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		height: 40px;
		/* Add an arrow indicator for select elements in WebKit browsers (Safari, Chrome, etc.) */
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23000000' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: right 0.5em top 50%;
		background-size: 0.65em auto;
		padding-right: 1.5em;
		border: transparent;
		border-radius: 0;
	}

    .gform_body {
        padding: 16px;
		.ginput_container_phone input {
			padding: 6px;
			border: transparent;
		}
    }

    .gfield_label,
    .gfield_required_text,
    .gfield_consent_label {
        color: white;
    }

	.gfield_required_text {
		font-style: italic;
	}

    .gfield_label,
    .ginput_container_consent {
        display: block !important;
        text-align: left !important;
    }

    .gfield_consent_label {
        font-size: 14px;
    }

    .gfield_consent_description {
        background-color: white;
        color: #252525;
    }

    #field_submit {
        flex-direction: row-reverse;
        justify-content: flex-start;

        .gform-button--white {
            margin-top: 8px;
            margin-bottom: 8px;
        }
    }

    .gform_ajax_spinner {
        width: 32px;
        margin-left: 8px;
        margin-right: 8px;
    }

    .gform_submission_error,
    .gfield_validation_message {
        font-size: 14px !important;
    }

    span.handwritten.thank-you {
        color: #fff;
        font-size: 28px;
        position: absolute;
        bottom: 44px;
        left: 35px;
    }

	.ginput_complex span.name_first {
		padding: 0 !important;
	}
}

.gform_confirmation_wrapper .gform_confirmation_message {
	font-size: 16px;
	font-weight: 500;
    color: white;
	padding: 2rem;
}

.quote-form-desktop .gform_footer.top_label {
    display: none !important;
}

.page-template-page-thank-you-for-your-enquiry-php,
.page-template-page-services {
    .form-panel {
        padding-left: 4rem;
        padding-right: 4rem;
    }

    .ginput_right.address_zip.ginput_address_zip {
        flex: 0 0 100%;
    }

    #gform_submit_button_13 {
        margin-right: 1rem;
    }

    #input_13_17 {
        color: #252525 !important;
    }
}

.page-template-page-franchise_opportunities {
    .gform_wrapper.gravity-theme .ginput_complex .ginput_full,
	.gform_wrapper.gravity-theme .ginput_container_address span {
		flex: 1;
    }
}

.page-template-page-franchise_opportunities,
.page-template-page-services {
	.gform_confirmation_message {
		text-align: center;
    	font-size: 18px;
	}

	@media (max-width: 767px) {
		.form-row.panel-form {
			position: absolute;
		}
	}
}

.single-area {
    .ginput_right.address_zip.ginput_address_zip {
        flex: 0 0 100%;
    }
}

.page-template-page-pai-php #pai_signup_form {
    .gform_wrapper {
        background-color: #568297;
    }

    .gform_validation_errors {
        display: flex;
        width: auto !important;
    }
    #gform_submit_button_16 {
        margin-left: 16px;
    }
}

.page-template-page-pai-faqs {
    .form-row {
        &.active {
            position: relative;
            top: 114px;
        }
    }
    #pai_signup_form {
        padding-bottom: 4px;
        .row {
            padding: 24px;
        }
    }
    .ctas {
        margin-bottom: 64px;
    }

    #gform_submit_button_16 {
        margin-left: 16px;
    }

    @media (max-width: 767px) {
        .gform_footer {
            justify-content: center;
        }
        #pai_signup_form {
            .row {
                background: rgba(68, 116, 140, 0.9);
            }
        }
    }
}

.page-template-page-download_prospectus,
.page-template-page-download_prospectus_2 {
	.form-holder h4 {
		text-align: left;
	}
	.gform_wrapper.gravity-theme {
		.gform_body {
			padding: 0;
		}
		span.handwritten.thank-you {
			position: static;
		}
	}
	.gform_confirmation_wrapper .gform_confirmation_message {
		padding-left: 0;
		text-align: left;
	}
	@media (min-width: 991px) {
		#main {
			height: 1360px
		}
	}
	@media (min-width: 767px) and (max-width: 991px) {
		#main {
			height: 1280px;
		}
	}
	@media (min-width: 768px) {
		.form-submitted {
			transform: translateY(384px);
		}
	}
}

.page-template-page-agencyqr {
	.gform_wrapper.gravity-theme {
		.gform_body {
			padding: 0;
		}
	}
	@media (max-width: 767px) {
		.form-row.panel-form {
			position: absolute;
		}
	}
}

.page-template-page-signmaster {
	@media (max-width: 767px) {
		.form-row.panel-form {
			position: absolute;
		}
	}
}

.page-template-page-pai {
	@media (max-width: 767px) {
		.form-row {
			position: absolute;
		}
	}
}

.area-template-default {
	.form-row {
		z-index: 2;
	}
	@media (max-width: 767px) {
		.form-row {
			position: absolute;
		}
	}
}
