body.page-template-page-charity {
	.banner .banner-title h1 span,
	.banner .banner-title p span,
	.intro h1, .intro h2,
	.flexible-content h3,
	.statistics .green-stat span { color: $charity; }
	.statistics .red-stat span { color: $greenPrimary; }
	.cards .card {
		.card-detail .card-content a.button-link {
			@include touch-hover() { color: $greenSecondary; }
		}
		@include touch-hover() {
			.card-detail { background: $greenSecondary; }
		}
	}
	.cards .card-orange {
		.card-detail {
			background: $charity !important;
			.card-content a.button-link {
				@include touch-hover() { color: $charity; }
			}
		}
		@include touch-hover() {
			.card-detail { background: $charity !important; }
		}
	}
}