/*====  Header Section Rules  ====*/
.warning-covid19 {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 3;
	p, ul li { color: $white; }
}

.warning-header {
	background: rgb(228,90,95);
	cursor: pointer;
	padding: 10px 0 0;
	@include transition-no-touch(background-color ease $animationSpeed);
	p.title {
		font-weight: 700;
		margin-bottom: 5px;
	}
	p { text-align: center; }
	@include touch-hover() { background-color: rgb(190,76,80); }
}

.warning-body {
	background: rgba(228,90,95,.9);
	display: none;
	padding: 20px 0 10px;
}


.site-header.notification-offset {
	top: 43px;
}

.site-header {
	background: $greenSecondary;
	height: 150px;
	font-family: 'Open Sans', sans-serif;
	position: fixed;
	top: 0;
	transition: all ease $animationSpeed;
	width: 100%;
	z-index: 3;
	#menu-main-navigation {
		font-size: 16px;
	}
	a {
		color: $white;
		@include transition-no-touch(color ease $animationSpeed);
	}
	a#mobile_form_toggle { display: none; }
	.form-toggle {
		display: block;
		font-size: 20px;
		margin-top: 56px;
		position: relative;
		text-transform: uppercase;
		transition: margin-top ease $animationSpeed;
		#quote_link, #close {
			cursor: pointer;
			position: absolute;
			top: 0;
			@include transition-no-touch(all ease $animationSpeed);
			@include touch-hover() {
				color: $greenSupporting;
				text-decoration: none !important;
			}
		}
		#quote_link {
			font-weight: 700;
			opacity: 1;
			left: 25px;
		}
		#close {
			left: -830px;
			padding-left: 53px;
			svg {
				height: 40px;
				left: 0;
				margin-right: 10px;
				position: absolute;
				top: -2px;
				width: 40px;
				path, rect {
					fill: $white;
					@include transition-no-touch(fill ease $animationSpeed);
				}
			}
			@include touch-hover() {
				svg {
					path, rect { fill: $greenSupporting; }
				}
			}
		}
		span.handwritten {
			margin-right: -40px;
			color: $white;
			float: right;
			font-size: 28px;
			line-height: 1.25;
			margin-top: -20px;
			opacity: 0;
			text-align: right;
			text-transform: initial;
			transition: opacity ease $animationSpeed;
			&.active { opacity: 1; }
		}
		&.active {
			#quote_link { opacity: 0; }
			#close { left: 25px; }
		}
	}
	.form-wrapper {
		background: $greenSecondary;
		position: absolute;
		right: 800px;
		top: 150px;
		transition: all ease $animationSpeed;
        transition-delay: .4s;
        width: 800px;
		z-index: 1;
		form {
		    float: right;
		    margin-right: 15px;
		    padding: 0 15px;
		    width: 400px;
		}
		&.active { right: -15px; }
	
	}
	#main_logo {
		display: block;
		margin: 8.7px auto ;
		transition: all ease $animationSpeed;
		width: 150px;
	}
	#signmaster_logo {
		float: right;
		margin-top: 48px;
		transition: margin-top ease $animationSpeed;
		width: 192px;
	}
	.menu-toggle {
		display: block;
		font-family: $headingFont;
		font-size: 20px;
		margin-top: 56px;
		position: relative;
		text-align: right;
		text-transform: uppercase;
		transition: margin-top ease $animationSpeed;
		#menu, #close {
			cursor: pointer;
			position: absolute;
			top: 0;
			transition: all ease $animationSpeed;
			svg {
				height: 40px;
				margin-right: 10px;
				position: absolute;
				right: 65px;
				top: -2px;
				width: 40px;
				path, rect {
					fill: $white;
					@include transition-no-touch(fill ease $animationSpeed);
				}
			}
			@include touch-hover() {
				color: $greenSupporting; 
				svg {
					path, rect { fill: $greenSupporting; }
				}
			}
		}
		#menu {
			opacity: 1;
			right: 25px;
		}
		#close { right: -630px; }
		&.active {
			#menu { opacity: 0; }
			#close { right: 25px; }
		}
	}
	.menu-wrapper {
		background: $greenSecondary;
		left: 600px;
		position: absolute;
		right: -800px;
		top: 150px;
		transition: all ease $animationSpeed;
		width: 600px;
		z-index: 5;
		ul {
			list-style: none;
			margin: 0 0 15px;
			padding-left: 30px;
			li {
				line-height: 1;
				margin: 20px 0;
				a {
					font-size: 18px;
					font-weight: 500;
					@include touch-hover() { color: $greenSupporting; }
				}
				&.current-menu-item a {
					color: $greenSupporting;
					font-weight: 700;
				}
			}
		}
		a#franchise_link, a#signmaster_login {
			background: $white;
			color: $greenSecondary;
			display: block;
			font-size: 18px;
			font-weight: 700;
			line-height: 1;
			padding: 30px;
			@include transition-no-touch(all ease $animationSpeed);
			@include touch-hover() {
				background: $greenSupporting;
				color: $white;
				text-decoration: none !important;
			}
		}
		a#signmaster_login {
			background: $signmasterSecondary;
			color: $white;
			padding: 17px 25px 15px;
			text-transform: uppercase;
			img { margin: -4px 5px 0 0; }
			@include touch-hover() { background: $signmasterPrimary; }
		}
		#signmaster_login > img {
			width: 150px;
		}
		&.active { left: -120px; }
	}
	.warning {
		background: rgba(228,90,95,.9);
        margin-bottom: 30px;
		opacity: 1;
		padding: 10px 0;
	    width: 100%;
	    transition: opacity ease $animationSpeed;
	    p.title {
	    	font-weight: 700;
	    	margin-bottom: 10px;
	    }
	    p {
	    	color: $white;
	    	margin-bottom: 0;
	    	text-align: center;
	    	a { font-weight: 700; }
	    }
	}
	&.minified {
		background: rgba(76,159,128,.9);
		height: 80px;
		.form-wrapper {
			background: rgba(76,159,128,.9);
			top: 80px;
		}
		#main_logo {
			margin-top: 4px;
			width: 80px;
		}
		.form-toggle, .menu-toggle { margin-top: 24px; }
		#signmaster_logo { margin-top: 14px; }
		.form-toggle span.handwritten { font-size: 24px; }
		.menu-wrapper {
			background: rgba(76,159,128,.9);
			top: 80px;
			a#franchise_link {
				background: rgba(255, 255, 255, 0.9);
				@include touch-hover() { background: rgba(138, 211, 191, 0.9); }
			}
			a#signmaster_login {
				background: rgba(84, 128, 181, 0.9);
				@include touch-hover() { background: rgba(70, 117, 147, 0.9); }
			}
		}
	}
}
.quote-form-content {
	width: 450px;
	position: fixed;
	top: 150px;
	background: #4b9f80;
	left: -500px;
	transition: all .3s ease;
	z-index: 10;
	.quote-form-inner{
		padding-left: 25px;
		padding-right: 25px;
	}
	.form-toggle {
		margin-top: 15px;
		display: block;
		font-size: 20px;
		position: relative;
		text-transform: uppercase;
		transition: margin-top .3s ease;
	}
	#close {
		color: #fff;
		left: 15px;
		padding-left: 53px;
		cursor: pointer;
		position: absolute;
		top: 0;
		transition: all .3s ease;
		svg {
			height: 40px;
			left: 0;
			margin-right: 10px;
			position: absolute;
			top: -2px;
			width: 40px;
			path { fill: #fff; }
		}
	}
	.handwritten {
		color:#fff;
		position: absolute;
		text-transform: initial;
		font-size: 24px;
		line-height: 1.25;
	}
	.form-toggle { display: none; }
	&.active { left: 0px; }
}

body.page-id-32 .quote-form-content, body.page-id-17 .quote-form-content { background: rgb(84, 128, 177); }

.quote-form-content .form-toggle {
	height: 70px;
	margin-top: 40px !important;
	margin-bottom: 40px;
	display: block !important;
}

.quote-form-inner .form-toggle {
	display: block !important;
	.handwritten { right: 0px; }
}

.not-active {
	pointer-events: none;
	cursor: default;
}

.fixedJump { position: absolute !important; }

.inv-recaptcha-holder{
	display:none;
}

a:where(:not(.wp-element-button)) {
    text-decoration: none !important;
}
