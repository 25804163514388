body { font-size: 14px; }

a.button-link, button, input[type=button], input[type=reset], input[type=submit], span.button-link { font-size: 13px; }

.xs-hidden { display: none; }

a#quote_mobile_form_toggle {
	cursor: pointer;
	display: block;
	font-family: Lato,sans-serif;
	font-weight: 700;
	height: 40px;
	padding-top: 8px;
	text-transform: uppercase;
	width: 100%;
	background: rgba(76,159,128,.9);
	bottom: 0;
	height: 40px;
	overflow: auto;
	position: fixed;
	text-align: center;
	width: 100%;
	transition: height .3s ease;
	z-index: 2;
	svg {
		height: 20px;
		margin-left: 15px;
		position: absolute;
		top: 9px;
		width: 20px;
		path{ fill:white;}
	}
	color:white;
}

.fixed-form {
	background: rgba(76,159,128,.9);
	bottom: 0;
	height: 40px;
	margin: 0 -15px;
	overflow: auto;
	position: fixed;
	text-align: center;
	width: 100%;
	transition: height ease $animationSpeed;
	z-index: 3;
	a#mobile_form_toggle {
		cursor: pointer;
		display: block;
		font-family: $headingFont;
		font-weight: 700;
		height: 40px;
		padding-top: 8px;
		text-transform: uppercase;
		width: 100%;
		svg {
			height: 20px;
			margin-left: 15px;
			position: absolute;
			top: 9px;
			width: 20px;
			path { fill: $white; }
		}
	}
	&.active {
		height: calc(100vh);
		padding-top: 20px;
		a#mobile_form_toggle{ display:none !important; }
	}
}

.site-header .fixed-form .form-toggle span.handwritten {
	font-size: 24px;
	right: 65px;
}

body.page-id-17, body.page-id-32 {
	.fixed-form { background: rgba(84,128,177,.9); }
	.intro {
		img { margin-bottom: 110px; }
		a.button-link {
			left: 50%;
			top: 70px;
			transform: translateX(-50%);
			-ms-transform: translateX(-50%);
    		width: 186px;
		}
	}
	.form-row {
		.form-dismiss { top: 25px; }
		p { text-align: center; }
		img#sm4_logo {
			margin-top: 70px;
			width: 250px;
		}
	}
}
body.page-id-10119 {
	.fixed-form { background: #00A89C !important; }
}
body.page-id-11640 {
	.fixed-form { background: #e53137 !important; }
}

.site-header.notification-offset {
	top: 39px;
}

.site-header {
	height: 90px;
	top: 0;
	.form-wrapper {
		background: none;
		left: 0;
		width: 100%;
		form {
			float: none;
			margin: 0 auto;
			max-width: 400px;
			width: 100%;
		}
		&.active { left: 0; }
	}
	.form-toggle {
		margin-top: 15px;
		width: 100%;
		#quote_link { display: none; }
		#close { left: 15px; }
		span.handwritten {
			float: none;
			margin-top: 0;
			opacity: 1;
			position: absolute;
			right: 15px;
		}
		&.active #close { left: 15px; }
	}
	#main_logo { width: 85px; }
	.menu-toggle {
		margin-top: 25px;
		#menu { right: 5px; }
		#close svg, #menu svg {
			margin-right: 0;
			right: 0;
		}
		&.active #close { right: 5px; }
	}
	.menu-wrapper {
		left: initial;
		top: 89px;
		width: 100vw;
		&.active {
			left: initial;
			right: 0;
		}
		ul { padding-left: 0; }
		ul li, a#franchise_link, a#signmaster_login { text-align: center; }
	}
	&.minified {
		height: 60px;
		#main_logo { width: 58px; }
		#signmaster_logo, .menu-toggle { margin-top: 10px; }
		.form-toggle { margin-top: 15px; }
		.menu-wrapper { top: 59px; }
		.form-wrapper {
			background: none;
			top: 150px;
		}
	}
}

div#main {
	padding: 167px 0px 0px 0px;
	margin-bottom: 50px;

	@media (max-width: 767px) {
		padding: 95px 0px 0px 0px;
	}
}

.banner {
	img { margin-top: 78px; }
	.banner-title-wrapper { top: 0; }
	.banner-title {
		background: $white;
		margin: 0 -15px;
		padding: 15px 0;
		position: relative;
		right: initial;
		h1, p {
			font-size: 22px;
			text-align: center;
		}
	}
}

body.page-id-310 .banner {
	img { margin-top: 0; }
	.banner-title {
		margin: 15px 0;
		padding: 15px;
		width: 100%;
		h1 {
			font-size: 28px;
    		margin: 0 0 15px;
    	}
    	form#operator_search { margin-bottom: 20px; }
    	p { font-size: 18px; }
	}
}

.intro {
	margin-bottom: 30px;
	h1, h2 {
		font-size: 24px;
	    margin: 20px 0 30px;
	    text-align: center;
	}
}

body.page-template-default .intro { margin-bottom: 20px; }

body.page-id-24 .flexible-content {
	margin-bottom: 0;
	.sr-left ul li, .sr-right ul li { margin-bottom: 35px; }
	.text-image-row { margin-bottom: 40px; }
}

body.page-id-30 .flexible-content {
	.sr-left ul li, .sr-right ul li {
		margin-bottom: 15px;
		h3 { font-size: 20px; }
		p { font-size: 14px; }
	}
	.sr-left ul { margin-bottom: 0; }
	.text-image-row {
		h4 { font-size: 18px; }
		&:last-child { margin-bottom: 0; }
	}
}

body.page-id-17 {
	.flexible-content {
		margin-bottom: 30px;
		h2 {
			font-size: 24px;
    		margin: 20px 0 30px;
    	}
    	.text-image-row {
    		margin-bottom: 50px;
    		h3 {
    			font-size: 20px;
    			margin-top: 30px;
    		}
    	}
	}
	.intro img { margin-bottom: 35px; }
}

body.single-post, body.archive, body.category, body.tag {
	h1 {
		font-size: 21px;
		margin: 20px 0 30px;
	}
	.intro { margin-bottom: 0; }
	.post-card {
		height: 290px;
		margin: 0 0 30px;
	}
}

body.page-id-77 {
	.intro {
		margin-bottom: 0;
		img { margin-bottom: 110px; }
		a.button-link {
			left: 50%;
			top: 140px;
			transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			width: 161px;
		}
	}
	.pai-posts a#view_prev_report { width: 290px; }
}

#pai_signup_form {
	height: 330px;
	padding-top: 40px;
	p.title { font-size: 21px; }
	p { font-size: 16px; }
	input[type=email] { margin-top: 5px; }
	input[type=submit] { margin-bottom: 30px; }
	.form-dismiss{
		top:0px;
	}
}

h3 { font-size: 21px; }

.flexible-content {
	h2 {
		font-size: 26px;
    	margin: 20px 0 30px;
	}
	h3 { margin: 10px 0; }
	.sr-left, .sr-right {
		img {
			margin-bottom: 30px;
			max-width: 435px;
		}
		h3 { margin-bottom: 10px; }
		ul {
			margin: 20px 0 30px;
			li {
				margin-bottom: 10px;
				padding-left: 80px;
				p.title { font-size: 16px; }
			}
		}
	}
	.twocolumn { margin-bottom: 40px !important; }
}

.form-row {
	height: auto;
	left: 800px;
	padding: 80px 0 0;
	width: 100%;
	.form-dismiss { right: 15px; }
	&.active { left: 0; }
}

.operator-form{
	.form-dismiss { right: 35px; }
}

.wpcf7 form h2 { font-size: 32px; }

body.page-id-4296 .flexible-content .text-image-row .form-row { height: auto; }

body.page-id-4298, body.page-id-4300 {
	.flexible-content { margin-bottom: 30px; }
}

.post-card {
	height: 100%; 
	margin-top: 30px;
}

.owl-carousel .post-card { height: auto; }

body.single-pai h1 {
	font-size: 21px;
	margin: 20px 0 30px;
}

.statistics {
	margin-bottom: 0;
	.stat {
		height: 160px;
		margin-bottom: 40px;
		img { height: 70px; }
		span { font-size: 30px; }
		p {
			font-size: 15px;
			padding: 0;
		}
	}
}

.testimonials {
	margin-bottom: 60px;
	h2 { font-size: 28px; }
	.testimonial-block {
		margin-left: -15px;
		height: 380px;
		min-height: 0;
		width: calc(100% + 30px);
		img#quote_mark { top: 25px; }
		.testimonial-wrapper {
			left: -1000px;
			padding: 30px;
			width: 100%;
			.img-wrapper {
				border-radius: 65px;
				-webkit-border-radius: 65px;
				-moz-border-radius: 65px;
				position: relative;
				right: initial;
				top: initial;
				transform: translateY(0);
				img {
					border-radius: 65px;
				    -webkit-border-radius: 65px;
				    -moz-border-radius: 65px;
					display: block;
					height: 130px;
					margin: 15px auto;
					width: 130px;
				}
			}
			&.active {
				left: 0;
				right: initial;
			}
		}
	}
	.left-testimonial {
		float: none;
		padding-left: 0;
		img#quote_mark { left: 15px !important; }
		.testimonial-wrapper {
			.quote, p.quotee { padding-right: 0; }
		}
	}
	.right-testimonial {
		padding-right: 0;
		img#quote_mark { left: 754px; }
		.testimonial-wrapper {
			.quote, p.quotee { padding-left: 0; }
		}
	}
	a#view_more {
		float: none;
		margin: 15px auto 0;
	}
}

.ctas .cta {
	margin-bottom: 15px;
	.cta-message p { font-size: 24px; }
}

#local_operator_cta .form-wrapper form {
	input[type=text] { height: 40px; }
	input[type=submit] {
		font-size: 15px;
		padding: 15px 40px;
	}
}

body.page-id-6 .ctas { margin-bottom: 15px; }

.full-width-ctas .cta .cta-message {
	p.title { font-size: 20px; }
	p { font-size: 13px; }
}

ul.tabs { height: auto; }

.tab-control {
	font-size: 14px !important;
	margin: 0 0 10px !important;
	padding: 8px 40px !important;
}

.tab-down-arrow-btn {
	right: 15px !important;
	width: 15px !important;
}

.tab-content {
	margin-top: 0 !important;
	padding: 0;
}

.tab-link:last-child .tab-control { text-align: center; }

.categories {
	p {
		font-size: 18px;
		margin-bottom: 15px;
		text-align: center;
	}
	a.button-link {
		font-size: 14px;
		margin: 0 0 15px;
		width: 100%;
	}
}

.post-card-fw {
	margin-top: 0;
	.post-detail {
		background: $greenSecondary;
		bottom: initial;
		margin-top: -1px;
		position: relative;
		width: 100%;
		p.type {
			color: $white;
			font-size: 16px;
    		margin-bottom: 5px;
		}
		p.name {
			color: $white;
			font-size: 20px;
    		line-height: 1.25;
		}
		p.date { color: $white; }
		ul.share li svg path { fill: $white; }
	}
	@include touch-hover() {
		.post-detail { background: $greenSecondary !important; }
	}
}

body.page-id-559 {
	.categories { margin-bottom: 30px; }
	h3 { font-size: 20px; }
	.spotlight { margin-bottom: 20px; }
	.news-posts .slider-wrapper { margin-top: 0; }
	.post-card { margin: 0 0 30px; }
}

body.archive h2 { font-size: 24px; }

body.page-id-1837 {
	.intro {
		margin-top: 30px;
		img{ width:250px; }
	}
	.faqs {
		h2 { font-size: 20px; }
		h3 { font-size: 16px; }
		ul li { margin-bottom: 30px; }
	}
}

.map {
	min-height: 671px;
	.form-panel {
		padding: 30px 30px 0;
		p, p.jumbo, p.address { text-align: center; }
		p.handwritten {
			line-height: 1;
    		margin-bottom: 30px;
		}
	}
}

body.page-id-4531 {
	.spotlight {
		h2 { font-size: 20px; }
		.button-link {
			display: inline-block;
			margin: 0 0 20px;
			@include touch-hover() {
				background: $white;
				color: $greenSecondary;
				text-decoration: none !important;
			}
		}
	}
	.post-card-fw {
		margin-bottom: 30px;
		.post-detail {
			background: $greenSecondary !important;
			bottom: 0;
			height: auto;
			position: relative;
			width: auto;
			p.location, p.name {
				color: $white;
				text-align: center;
			}
			p.location {
				font-size: 18px;
				margin-bottom: 20px;
			}
			.card-content {
				display: block;
				p {
					color: $white;
					font-size: 16px;
					line-height: 1.75;
					margin-bottom: 20px;
					padding: 0 15px;
					text-align: center;
				}
			}
		}
	}
	.cards .team-member-card .card-detail {
		p.title { font-size: 20px; }
		p.role {
			font-size: 14px;
			margin-bottom: 15px;
		}
		.card-content {
			p { display: none; }
			a.button-link { margin-bottom: 5px; }
		}
	}
}

body.single-success-story {
	.two-column-text p { text-align: left; }
	.pagination {
		.controls { margin-bottom: 40px; }
		a.button-link-svg { width: 260px; }
	}
}

body.single-area {
	.intro {
		img { margin: 20px auto; }
		h2 { margin-bottom: 100px; }
		a.button-link {
			right: 50%;
			top: 60px;
			transform: translateX(50%);
			-ms-transform: translateX(50%);
		}
		.wpcf7 form h2 { margin-bottom: 10px; }
	}
	.region-map {
		margin-bottom: 50px;
		img { padding: 0 15px; }
		.areas-panel-wrapper {
			transform: initial;
			-ms-transform: initial;
			.areas-panel { font-size: 16px; }
		}
	}
}

body.page-id-4806 {
	.intro {
		margin-bottom: 90px;
	}
	.handwrittenWrapper{ text-align:left;}
}

body.error404 .intro {
	margin-top: 40px;
	img {
		margin-top: -10px;
		width: 70px;
	}
	p { padding-left: 90px; }
}

body.page-id-26 {
	.cards .card .card-detail { min-height: 0; }
	.statistics { margin-bottom: 50px; }
}

body.page-id-4817 {
	.map .form-panel-wrapper {
		.form-panel {
			margin-top: 50px;
		}
	}
}
.contactMap{
	height: 790px;
	width: 100%;
}

body.page-id-4817 #map { height:1000px; }

body.page-id-310 .banner{
	max-height: initial;
	.banner-title-wrapper{ position: relative; }
}

.faqs{
	h4{ font-size: 16px; }
}

/*form styles*/
.panel-form .wpcf7-response-output{
	position: relative;
	margin-left:15px;
	margin-right:15px;
	left:0px;
	bottom: 30px;
}

#pai_signup_form {
	.wpcf7-response-output{
		margin-top:0px;
		margin-left: 15px;
		margin-right: 15px;
	}
}
.quote-form-mobile{
	display:block;
	width: 100%;
	position: absolute;
	top:-1500px;
	background: rgba(76,159,128,.9);
	margin-bottom:20px;
	transition: all .25s ease;
	overflow:hidden;
	z-index: 4;
}
.quote-form-mobile.active{
	padding-top: 20px;
	top:0px;
}
.panel-signmaster-form{
	.wpcf7-response-output{
		margin-top: 0px;
		margin-left:0px;
		margin-right:0px;
		position:initial;
		display:none;
	}
	.form-fields-wrapper{
		text-align:left;
	}
}

.page-template-page-download_prospectus .form-panel form .wpcf7-response-output{
	margin-left: 0px;
	margin-right:0px;
}

.page-template-page-thank-you-for-your-enquiry .form-panel form .wpcf7-response-output{
	margin-left: 15px;
	margin-right:15px;
}

.single-area form .wpcf7-response-output{
	left:0px;
	margin-left:15px;
	margin-right:15px;
}

body.page-template-page-download_prospectus_2{
	.banner-title-wrapper{
		position:relative !important;
	}
	.banner{
		max-height:none !important;
	}
	.banner img{
		margin-top: 0px;
	}
}

body.single-post div#main.pai-post a.button-link-svg{
	margin: 20px auto;
	float: initial;
	display: block;
	width: 210px;
}

iframe {
	height: 240px;
	width: 420px;
}

body.page-template-page-success-stories .cards .card {
	.card-detail { height: auto !important; }
	@include touch-hover() {
		.card-detail { height: auto !important; }
	}
}

.gallery .modal .modal-image-wrapper iframe {
    height: 240px;
    width: 420px;
}

.site-footer #footer_achievements {
    .affiliations img,
    .awards img,
    .suppliers img { max-width: 260px; }
}

/* No Hover effects for CTA's on mobile */
.cards {
	margin-bottom: 0;
	.card {
		margin-bottom: 30px;
		padding-bottom: 0;
		height: 100%;
		img {
			height: 204px;
			width: 100%;
		}
		.card-detail {
			height: auto;
			margin-top: -1px;
			padding-top: 15px;
			position: relative;
			img {
				height: auto;
				margin: 0 auto 15px;
				opacity: 1;
				width: 50px;
			}
			p.title { margin-top: 15px; }
			p {
				font-size: 16px;
				margin-bottom: 20px;
			}
			.card-content {
				opacity: 1;
				a.button-link {
					margin-bottom: 20px;
					position: relative;
					@include touch-hover() { color: $greenSecondary; }
				}
			}
		}
		@include touch-hover() {
			.card-detail {
				background: $greenSecondary !important;
				height: auto !important;
				p.title, p { text-decoration: none !important; }
			}
		}
	}
	.signmaster-card {
		.card-detail .card-content a.button-link {
			@include touch-hover() { color: $signmasterSecondary !important }
		}
		@include touch-hover() {
			.card-detail { background: $signmasterSecondary !important; }
		}
	}
	.team-member-card {
		img {
			height: auto;
			width: 100%;
		}
		.card-detail {
			padding-bottom: 15px;
			p {
				font-size: 14px;
				padding: 0 15px;
			}
			p.title {
				font-size: 28px;
				margin-top: 10px;
			}
			p.role {
				font-size: 15px;
				margin-bottom: 10px;
			}
		}
		@include touch-hover() {
			.card-detail {
				height: 290px;
				p.title { margin-top: 10px; }
			}
		}
	}

	@media (max-width: 767px) {
		.cta-card .cta-card-image {
			width: 100%;
			height: 320px;
			object-fit: cover;
		}
	}
	@media (max-width: 560px) {
		.cta-card .cta-card-image {
			width: 100%;
			height: 320px;
			object-fit: cover;
		}  
	}
	@media (max-width: 560px) {
		.cta-card .cta-card-image {
			width: 100%;
			height: 240px;
			object-fit: cover;
		}  
	}
	@media (max-width: 320px) {
		.cta-card .cta-card-image {
			width: 100%;
			height: 160px;
			object-fit: cover;
		}  
	}
}