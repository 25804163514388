.banner {
	max-height: 650px;
	overflow: hidden;
	position: relative;
	img {
		height: auto;
		width: 100%;
	}
	.banner-title-wrapper {
		position: absolute;
		top: 90px;
		width: 100%;
	}
	.banner-title {
		background: rgba(255,255,255,.9);
		padding: 25px 1000px 25px 50px;
		position: absolute;
		right: -985px;
    	width: auto;
		h1, p {
			color: $lightGrey;
			font-family: $headingFont;
			font-size: 48px;
			line-height: 1.125;
			margin: 0;
			text-align: right;
			span {
				color: $greenSecondary;
				font-weight: 700;
			}
		}
	}
}