.cards {
	margin-top: 50px;
	margin-bottom: 50px;
	.row {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
		justify-content: center;
    }
	.card {
		cursor: pointer;
		overflow: hidden;
		padding-bottom: 110px;
		position: relative;
		margin-top: 15px;
		margin-bottom: 15px;
		width: 256px;
		height: 336px;
		@media (max-width: 767px) {
			width: auto;
		}
		.card-detail {
			background: $greenSecondary;
			bottom: 0;
			height: 96px;
			position: absolute;
			transition: all ease $animationSpeed;
			width: 100%;
			@media (max-width: 767px) {
				height: auto;
			}
			img {
				display: block;
				margin: 16px auto 12px;
				opacity: 0;
				transition: all ease $animationSpeed;
			}
			p.title {
				display: block;
				font-family: $headingFont;
				font-size: 25px;
				font-weight: 300;
				line-height: 1;
				margin: -89px auto 15px;
				transition: all ease $animationSpeed;
			}
			p {
				color: $white;
				font-size: 16px;
				padding: 0 20px;
				text-align: center;
			}
			.card-content {
				opacity: 0;
				position: relative;
				transition: all ease $animationSpeed;
				a.button-link {
					background: transparent;
					border-color: $white;
					color: $white;
					font-size: 14px;
					left: 50%;
					padding: 12px;
					position: absolute;
					transform: translateX(-50%);
					-ms-transform: translateX(-50%);
					@include touch-hover() {
						color: $redSecondary;
						background: $white;
						text-decoration: none !important;
					}
				}
			}
		}
		@include touch-hover() {
			.card-detail {
				background: $redSecondary;
				height: 100%;
				img, .card-content { opacity: 1; }
				p.title, p { text-decoration: none !important; }
				p.title { margin-top: 0; }
			}
		}
	}
	.cta-card {
		width: auto;
		.cta-card-image {
			width: 100%;
			height: 240px;
			object-fit: cover;
		}
		.cta-card-icon {
			width: 80px;
			object-fit: cover;
		}
	}
	.signmaster-card {
		.card-detail {
			background: $blueSupporting;
			.card-content a.button-link {
				@include touch-hover() { color: $blueSupporting; }
			}
		}
		@include touch-hover() {
			.card-detail { background: $blueSupporting; }
		}
	}
	.card.team-member-card {
		cursor: initial;
		margin-bottom: 30px;
		width: 372px;
		@media (max-width: 1229px) {
			width: 100%;
		}
		.card-detail {
			.card-content {
				opacity: 0;
				position: relative;
				transition: all ease $animationSpeed;
			}
			a.button-link {
				background: transparent;
				border-color: $white;
				color: $white;
				font-size: 14px;
				padding: 12px;
				width: 128px;
				margin: 0 auto;
				@include touch-hover() {
					color: $redSecondary;
					background: $white;
					text-decoration: none !important;
				}
			}
			p { padding: 0 30px; }
			p.title { margin: 13px 0 5px; }
			.social-links {
				bottom: 20px;
				opacity: 0;
				position: absolute;
				text-align: center;
				transition: opacity ease $animationSpeed;
				width: 100%;
				a {
					cursor: pointer;
					display: inline-block;
					margin-right: 40px;
					svg {
						height: 35px;
						width: 35px;
						path {
							fill: $white;
							@include transition-no-touch(fill ease $animationSpeed);
						}
					}
					&:last-child { margin-right: 0; }
				}
				a#facebook {
					@include touch-hover() {
						svg path { fill: $facebook; }
					}
				}
				a#linkedin {
					@include touch-hover() {
						svg path { fill: $linkedin; }
					}
				}
				a#twitter {
					@include touch-hover() {
						svg path { fill: $twitter; }
					}
				}
				a#instagram {
					@include touch-hover() {
						svg path { fill: $instagramPurple; }
					}
				}
			}
		}
		@include touch-hover() {
			.card-detail {
				p.title { margin-top: 30px; }
				.social-links { opacity: 1; }
			}
		}
	}
	
}

@media (min-width: 1230px) {
	#cardsWrapper.cards {
		flex: 1 1 50%;
	}
}	

body.page-template-page-success-stories {
	.cards {
		margin-top: 0;
		margin-bottom: 112px;
		@media (max-width: 1229px) {
			margin-top: 64px;
			margin-bottom: 64px;
		}
		.card.team-member-card {
			height: 384px;
			margin-top: 0px;
			.card-detail {
				height: 80px;
			}
			@media (max-width: 1229px) {
				height: 586px;
				.card-detail {
					display: flex;
					flex-direction: column;
					justify-content: center;
					height: 100%;
						img, .card-content { opacity: 1; }
						p.title, p { text-decoration: none !important; }
						p.title { margin-top: 0; }
				}
			}
			@media (max-width: 767px) {
				height: auto;
			}
			
			@include touch-hover() {
				.card-detail {
					height: 384px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					p.title { margin-top: 0px; }
				}
			}
			img  {
				position: relative;
				top: 45px;
				@media (max-width: 1229px) {
					top: 0;
				}
				@media (max-width: 991px) {
					top: 0;
				}
			}
		}
	}
	.intro {
		margin-bottom: 0px;
	}
}

.wp-block-group.is-nowrap .card-width {
	width: 100%;
	@media (max-width: 1229px) {
		width: 288px;
	}
	@media (max-width: 640px) {
		width: 100%;
	}
}
.wp-block-group.is-vertical .card-width {
	width: 384px;
	@media (max-width: 640px) {
		width: 100%;
	}
}