div#main {
	overflow: hidden;
	padding: 156px 0 0 0;
	margin-bottom:48px;
}

.intro {
	margin-bottom: 50px;
}

.relative{
	position:relative;
}

.form-row-wrapper { position: relative; }

.form-row {
    background: rgba(76,159,128,.9);
    transform: translateX(200%);
    padding: 80px 0px 50px;
    position: absolute;
    transition: transform .5s ease-in-out;
    z-index: 1;
    width:100%;
    .form-dismiss {
    	cursor: pointer;
	    position: absolute;
	    right: 10px;
	    top: -50px;
	    z-index: 2;
    	svg {
    		height: 30px;
    		width: 30px;
    		path {
    			fill: $white;
    			@include transition-no-touch(fill ease $animationSpeed);
    		}
    	}
		@include touch-hover() {
    		svg path { fill: rgba(255, 255, 255, 0.7); }
    	}
    }
    h3, p {
    	color: $white;
    	text-align: left;
    }
    h3 {
    	font-weight: 700;
    	margin-top: 0;
    	span { font-weight: 400; }
    }
    form {
    	position: relative;
    	.form-field {
			p.handwritten { padding-top: 30px; }
			input[type="submit"] { margin-top: 35px; }
		}
	}
    &.active { 
    	transform: translateX(0);
    }
}
.flexible-content .sr-left a.button-link, .sr-right a.button-link {
	@include touch-hover() {
		background: $redSecondary !important;
		color: $white;
		text-decoration: none !important;
	}
}
.flexible-content .sr-left a.button-link-svg, .sr-right a.button-link-svg {
	@include touch-hover() {
		svg path { fill: $white; }
	}
}

.flexible-content {
	margin-bottom: 50px;
	h3, p { text-align: center; }
	h3 { margin: 20px 0 25px; }
	.twocolumn p { text-align: left; }
	.text-image-row {
		margin-bottom: 80px;
		position: relative;
		.form-trigger { margin-top: 0; }
		&:last-child { margin-bottom: 0; }
	}
	.sr-left, .sr-right {
		h3, p { text-align: left; }
		h3 { margin: 0 0 15px; }
		ul {
			list-style: none;
			margin: 30px 0 40px;
			padding-left: 0;
			li {
				margin-bottom: 25px;
				padding-left: 90px;
				position: relative;
				img {
					left: 0;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					-ms-transform: translateY(-50%);
				}
				p.title {
					font-family: $headingFont;
					font-size: 18px;
				}
				p { margin-bottom: 0; }
			}
		}
		a.button-link {
			border-color: $redSecondary;
			color: $redSecondary;
			cursor: pointer;
			padding: 15px 40px;
		}
		a.button-link-svg {
			svg path { fill: $redSecondary; }
		}
		p:last-child { margin-bottom: 0; }
	}
	.form-row {
	    h3, p {
	    	color: $white;
	    	text-align: left;
	    }
	    h3 {
	    	font-weight: 700;
	    	margin: 0 0 10px;
	    	span { font-weight: 400; }
	    }
	}
}

.gallery {
	margin-bottom: 30px;
	.modal {
		background: rgba(255,255,255,.9);
		display: none;
		height: 100vh;
		left: 0;
		position: fixed;
		top: 0;
		width: 100vw;
		z-index: 3;
		.modal-image-wrapper {
			position: relative;
			height:100%;
			a.modal-dismiss {
				position: absolute;
				right: 60px;
				top: 60px;
				z-index: 99;
				svg {
					height: 30px;
					width: 30px;
					path {
						fill: $greenSecondary;
						@include transition-no-touch(fill ease $animationSpeed);
					}
				}
				@include touch-hover() {
					svg path { fill: $greenSupporting; }
				}
			}
			img, iframe {
				background: $white;
				box-shadow: 10px 10px 40px 0px rgba(0,0,0,0.4);
				display: block;
				margin: 0 auto;
				max-height: 90%;
				max-width: 90%;
				position: relative;
				top: 50%;
				transform: translateY(-50%);
			}
		}
		&.active { display: block; }
	}
	.gallery-image-wrapper {
		position: relative;
		svg {
			background: $greenSecondary;
			bottom: 0;
			height: 40px;
			left: 0;
			padding: 5px;
			position: absolute;
			transition: all ease $animationSpeed;
			width: 40px;
			path { fill: $white; }
		}
		img { margin-bottom: 30px; }
		a {
			@include touch-hover() {
				svg  {
					height: 50px;
					width: 50px;
				}
			}
		}
	}
}

.pagination {
	margin-bottom: 50px;
	.controls {
		border-top: 1px solid $lightGrey;
		margin-bottom: 50px;
		padding-top: 30px;
		position: relative;
		.prev, .next {
			cursor: pointer;
			font-family: $headingFont;
			font-size: 21px;
			font-weight: 700;
			line-height: 1;
			position: absolute;
			text-transform: uppercase;
			a { color: $greenSecondary; }
			svg {
				height: 17px;
				width: 17px;
				path {
					fill: $greenSecondary;
					@include transition-no-touch(fill ease $animationSpeed);
				}
			}
			@include touch-hover() {
				a { color: $greenSupporting; }
				svg path { fill: $greenSupporting; }
			}
		}
		.prev {
			left: 0;
			svg { margin-right: 7px; }
		}
		.next {
			right: 0;
			svg { margin-left: 7px; }
		}
	}
	a.button-link {
		display: block;
		margin: 0 auto;
		width: 230px;
		@include touch-hover() {
			background: $greenSecondary;
			color: $white;
			text-decoration: none !important;
		}
	}
}

.share {
	ul {
		list-style: none;
		margin: 0 0 30px;
		padding-left: 0;
		li { display: inline-block; }
	}
}

.tags {
	font-family: $headingFont;
	p {
		color: $greenSecondary;
		font-size: 20px;
		font-weight: 700;
		margin-bottom: 0;
	}
	ul {
		list-style: none;
		margin: 0 0 30px;
		padding-left: 0;
		li {
			background: $greenSecondary;
			display: inline-block;
			margin: 0 10px 10px 0;
			@include transition-no-touch(background ease $animationSpeed);
			a {
				color: $white;
				display: block;
				font-size: 12px;
				padding: 3px 10px;
				text-transform: uppercase;
			}
			@include touch-hover() {
				background: $greenSupporting;
				a { color: $white; }
			}
		}
	}
}

.map {
	position: relative;
	.form-panel-wrapper {
		position: absolute;
		top: 0;
		width: 100%;
	}
	.form-panel {
		background: rgba(76,159,128,.9);
		margin-top: 30px;
		padding-top: 30px;
		p { color: white; }
		p.handwritten { font-size: 34px; }
		p.jumbo, p.address { text-align: right; }
		p.jumbo { margin-bottom: 15px; }
		p.address {
			line-height: 1.3;
			margin-bottom: 25px;
		}
	}
}

.faqs {
	h3, h4 { color: $paiBlue; }
	h3 { text-align: center; }
	h4 {
		font-family: $bodyFont;
		font-size: 20px;
		line-height: 1.4;
		cursor:pointer;
	}
	ul {
		list-style: none;
		padding-left: 0;
		li {
			margin-bottom: 50px;
			position: relative;
			a.expand {
				border: 1px solid $paiBlue;
				color: $paiBlue;
				display: inline-block;
				font-size: 24px;
				height: 25px;
				left: 0;
				line-height: .9;
				position: absolute;
				text-align: center;
				top: 5px;
				@include transition-no-touch(all ease $animationSpeed);
				width: 25px;
				@include touch-hover() {
					background: $paiBlue;
					color: $white;
					text-decoration: none !important;
				}
			}
			.faq-wrapper {
				border-bottom: 1px solid $paiBlue;
				margin-left: 40px;
				.expanded {
					color:$lightGrey;
					display: none;
					margin-bottom: 20px;
				}
			}
		}
	}
}

#pai_signup_form {
	background: rgba(68,116,140,.9);
	padding-top: 60px;
	p.title {
		font-size: 32px;
		line-height: 1.3;
		margin-bottom: 10px;
	}
	p {
		font-size: 21px;
		line-height: 1.3;
	}
	input[type="email"] { margin-top: 0px; }
	input[type=submit] {
		@include touch-hover() { color: $paiBlue; }
	}
	.wpcf7-response-output{ margin-top:5px; }
}

.categories {
	margin-bottom: 25px;
	p {
		color: $greenSecondary;
		font-family: $headingFont;
		font-size: 20px;
		font-weight: 700;
		margin-bottom: 5px;
		text-align: right;
	}
	a.button-link {
		border: 2px solid $greenSecondary;
		color: $greenSecondary;
		cursor: pointer;
		display: inline-block;
		float: left;
		font-family: $headingFont;
		font-size: 16px;
		font-weight: 700;
		line-height: 1;
		margin-right: 15px;
		padding: 15px;
		text-align: center;
		text-transform: uppercase;
		@include transition-no-touch(all ease $animationSpeed);
		width: 24.03%;
		span { font-weight: 400; }
		&#cat_437 {
			border-color: $paiBlue;
			color: $paiBlue;
			@include touch-hover() {
				background: $paiBlue;
				color: $white;
				text-decoration: none !important;
			}
		}
		&:last-child { margin-right: 0; }
		@include touch-hover() {
			background: $greenSecondary;
			color: $white;
			text-decoration: none !important;
		}
	}
}

ul.tabs { height: 42px; }

.tab-content {
	margin-top: 50px;
	padding: 0;
}

.banner .banner-title .download-link {
	background: rgba(76,159,128,.9);
	border: 2px solid $white;
	bottom: 25px;
	color: white;
	margin: 25px 0;
	padding: 20px;
	width: 350px;
	p, p span {
	    margin-bottom: 0;
	    color: white;
	    font-size: 18px;
	    line-height: 1.5;
	    text-align: center;
	}
	&.active { display: block; }
}

#view_prev_years { margin-top: 30px !important; }

.pai-post .banner .banner-title h1 span { color: #5480b1; }

.mobile-reorientation { display: none; }