/*!
	Theme Name: 	Agency Express
	Theme URI:
	Description: 	Modern responsive theme
	Version: 		2.0
	Author: 		Accent Design Group Ltd - Tim, Ben, Nick & Craig
	Author URI: 	https://accentdesign.co.uk
	Tags:
*/

/*==== This is the single file output by SCSS. It is intended to ONLY @import other files. ====*/

/*==== 1 - Vendor (3rd Party Styles) ====*/
@import "1-vendors/normalize.scss";
@import "1-vendors/bootstrap-grid.min.scss";
@import "1-vendors/bootstrap-transition.scss";
@import "1-vendors/bootstrap-border-radius.scss";
@import "1-vendors/bootstrap-box-shadow.scss";
@import "1-vendors/bootstrap-breakpoint.scss";
@import "1-vendors/bootstrap-variables.scss";
@import "1-vendors/bootstrap-modal.scss";
@import "1-vendors/owl.carousel.scss";
@import "1-vendors/mixins.scss";

/*==== 2 - Base (Global Site Styles) ====*/
@import "2-base/colourscheme.scss";
@import "2-base/typography.scss";
@import "2-base/body-element.scss";
@import "2-base/links.scss";
@import "2-base/buttons.scss";
@import "2-base/form.scss";
@import "2-base/table.scss";
@import "2-base/media.scss";
@import "2-base/utilities.scss";

/*==== 3 - Components (Micro & Macro Layout Styles) ====*/
@import "3-components/header.scss";
@import "3-components/footer.scss";
@import "3-components/banner.scss";
@import "3-components/cookie-banner.scss";
@import "3-components/cards.scss";
@import "3-components/post-cards.scss";
@import "3-components/card-slider.scss";
@import "3-components/statistics.scss";
@import "3-components/ctas.scss";
@import "3-components/testimonials.scss";
@import "3-components/spinner.scss";
@import "3-components/quote-form.scss";
@import "3-components/accordion.scss";
@import "3-components/gravity-form.scss";
@import "3-components/squares.scss";
@import "3-components/article-cards.scss";
@import "3-components/quote.scss";
@import "3-components/business-information.scss";

/*==== 4 - Pages (Page Specific Styles) ====*/
@import "4-pages/page-elements.scss";
@import "4-pages/pages.scss";
@import "4-pages/signmaster-pages.scss";
@import "4-pages/page-pai_archive.scss";
@import "4-pages/charity.scss";
@import "4-pages/privacy_policy.scss";
@import "4-pages/page-franchise_resale_opportunities.scss";
@import "4-pages/single-franchise-resale-opp.scss";
@import "4-pages/page-initial-application-form.scss";
@import "4-pages/block-template.scss";
@import "4-pages/agencyqr.scss";
@import "4-pages/agency_key_safe.scss";

/*==== 4 - Pages (Page Specific Styles) ====*/
@import "6-miscellaneous/utilities.scss";

/*==== 5 - Responsive (Mobile Device Styles) ====*/
/* Site is built 'Desktop First' so all styles up to this point will cover Large Desktop (Large) screen sizes of >=1230px */

/* Desktop/Tablet Landscape (Medium) screen sizes 992-1229px */
@media only screen and (max-width: 1229px) {
	@import "5-responsive/1229down.scss";
}

/* Tablet Portrait (Small) screen sizes 768-991px */
@media only screen and (max-width: 991px) {
	@import "5-responsive/991down.scss";
} 

/* Mobile Experience STARTS HERE!!! */
/* Phablet/Mobile Landscape (Extra Small) screen sizes 480-767px */
@media only screen and (max-width: 767px) {
	@import "5-responsive/767down.scss";
}

/* Mobile Portrait screen sizes <=479px */
@media only screen and (max-width: 479px) {
	@import "5-responsive/479down.scss";
}


/*==== 6 - Miscellaneous (Utility Styles) ====*/
/* Retina Displays (2x Resolution Devices) */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min--moz-device-pixel-ratio: 1.5),
only screen and (min-device-pixel-ratio: 1.5) {
	@import "6-miscellaneous/2x.scss";
}

/* Print */
@media print {
	@import "6-miscellaneous/print.scss";
}

.text {
	display: block;
	color: #000;
}

/*==== PAI Reports ====*/

@import "4-pages/pai_reports_public_style.scss";


/*==== Disable All Animations ====*/
@import "6-miscellaneous/disable-animations.scss";