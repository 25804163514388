/* AgencyQR Page */
body.page-id-10119 {
	background: none;
	#main {
		background-image: linear-gradient(to bottom,#fff 0,#fff 100%),linear-gradient(to bottom,#00877D 0,#00877D 100%);
		background-clip: content-box,padding-box;
	}
	h1, h2, h3, h4, h5, h6 {
		color: #00877D;
	}
	.site-header {
		background-color: #00877D;
		.container-fluid.greenBG,
		.menu-wrapper {
			background-color: #00877D;
		}
	} 
	.banner-title {
		h1 {
			span{
				color: #00877d;
			}
		}
	}
	.introqr { 
		#sm4_form_trigger_1 {
			position: absolute;
			top: 75px;
			right: 0;
			color: #00A89C;
    		border-color: #00A89C;
			svg path {
				fill: #00A89C;
			}
			@media (max-width: 1229px) {
				top: 32px;
			}	
			@media (max-width: 991px) {
				top: 124px;
				left: 50%;
				transform: translateX(-50%);
				-ms-transform: translateX(-50%);
				width: 200px;
			}	
			@media (max-width: 767px) {
				width: 186px;
			}	
			@media (max-width: 479px) {
				top: 100px;
			}	
		}
	}
	.quote-form-mobile,
	.quote-form-desktop,
	#quote_mobile_form_toggle {
		background-color: #00877d !important;
	}
	#signmaster_form_1 {
		background-color: #00877dde;
		padding-bottom: 16px;

		.form-dismiss {
			@media (min-width: 1920px) {
				position: fixed;
				top: 46px;
				right: 118px;
			}
			@media (max-width: 767px) {
				margin-top: 16px;
				margin-bottom: 80px;
			}
			@media (max-width: 479px) {
				margin-top: -12px;
			}
		}
		.title,
		.sub-title {
			@media (max-width: 479px) {
				text-align: center;
			}
		}
	}
	.testimonials .testimonial-block,
	.site-footer #footer_green {
		background-color: #00A89C;
	}
	.site-footer {
		#footer_green {
			#scroll_top {
				filter: hue-rotate(29deg);
				svg g circle {
					fill: rgba(19, 108, 102, 0.7);
				}
			}
			h3 {
				color: white;
				font-size: 16px;
			}
			.social a,
			.contact p {
				color: #ffffffcc
			}
		}
		#footer_achievements {
			h3,
			p.handwritten {
				color: #00877d;
			}
		}
	}

	.product-feature-spotlights {
		.product-steps-spotlight {
			height: 224px;
			margin-top: 112px;
			margin-left: 56px;

			@media (max-width: 1600px) {
				height: 208px;
			}
			@media (max-width: 1230px) {
				height: 176px;
				margin-left: 36px;
			}
			@media (max-width: 991px) {
				height: 144px;
				margin-left: 20px;
			}
			@media (max-width: 767px) {
				height: 192px;
				margin-top: 8px;
				margin-left: 28px;
				margin-right: 28px;
			}
			@media (max-width: 479px) {
				height: 144px;
				margin-left: 8px;
				margin-right: 8px;
			}
			@media (min-width: 1920px) {
				margin-left: 96px;
			}
			@media (min-width: 2350px) {
				margin-left: 160px;
				.form-row .form-dismiss {
					position: relative;
    				left: 1046px;
				}
			}
		}
		.qr-hand-spotlight {
			height: 288px;
			position: fixed;
			top: 244px;
			right: 0;
			@media (max-width: 1600px) {
				height: 256px;
				top: 526px;
			}
			@media (max-width: 1230px) {
				height: 240px;
				top: 512px;
			}
			@media (max-width: 991px) {
				height: 224px;
			}
			@media (max-width: 767px) {
				top: auto;
				bottom: -15px;
				z-index: 99;
			}
			@media (max-width: 479px) {
				height: 160px;
			}
		}
		.house-row-spotlight {
			height: 208px;
			position: relative;
			top: 155px;
			right: 46px;
			@media (max-width: 1600px) {
				height: 176px;
				top: 200px;
			}
			@media (max-width: 1230px) {
				height: 144px;
				top: 262px;
			}
			@media (max-width: 991px) {
				height: 128px;
				top: 337px;
			}
			@media (max-width: 767px) {
				top: 26px;
			}
			@media (max-width: 479px) {
				height: 120px;
			}
			@media (min-width: 2350px) {
				right: -96px;
			}
		}
		.product-spotlights-mobile {
			display: flex;
			flex-direction: row;
			@media (max-width: 767px) {
				justify-content: center;
				
				margin-top: 16px;
				margin-bottom: 80px;
			}
			@media (max-width: 479px) {
				margin-bottom: 36px;
			}
			@media (max-width: 280px) {
				flex-direction: column;
				margin-left: 32px;
				margin-right: 32px;
			}
			@media (max-width: 180px) {
				margin-left: 4px;
				margin-right: 4px;
			}
			img {
				@media (max-width: 280px) {
					height: auto;
					width: 100%;
					margin-bottom: 16px;
				}
			}
		}
	}
	.gform_wrapper .handwritten.thank-you {
		@media (max-width: 280px) {
			display: none;
		}
	}
	.gform_confirmation_message {
		font-weight: bold;
		font-size: 20px;
	}
}
