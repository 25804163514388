/* --- Layout ------------------------------------ */

/* Display Block */
.block {
	display: block !important;
}

@media (max-width: 767px) {
	.block--md {
		display: block !important;
    }
}


/* Display None */
.hidden {
	display: none;
}

@media (max-width: 767px) {
	.hidden--md {
		display: none !important;
    }
}


/* Display Inline */
.inline {
	display: inline;
}


/* Flexbox */
.flex {
	display: flex;
}

.flex-row {
	flex-direction: row;
}

.flex-col {
	flex-direction: column;
}

@media (max-width: 767px) {
	.flex-col-md {
		flex-direction: column;
    }
}

.justify-center {
	justify-content: center;
}

.items-center {
	align-items: center;
}

/* Static Positining */
.static {
	position: static;
}

@media (max-width: 767px) {
	.static--md {
		position: static !important;
	}
}


/* Relative Positining */
.relative {
	position: relative;
}

@media (max-width: 767px) {
	.relative--md {
		position: relative;
	}
}

.top-0 {
	top: 0px;
}

.top-486px {
	top: 486px;
}

@media (max-width: 767px) {
	.top-486px-md {
		top: 486px;
	}
}

.bottom-0 {
	bottom: 0px;
}

.bottom-8px {
	bottom: 8px;
}

.bottom-24px {
	bottom: 24px;
}

@media (max-width: 767px) {
	.bottom-24px-md {
		bottom: 24px;
	}
}

@media (max-width: 359px) {
	.bottom-50px-sm {
		bottom: 50px;
	}
}

@media (max-width: 226px) {
	.bottom-78px-ssm {
		bottom: 78px;
	}
}

.bottom-30px {
	bottom: 30px;
}

@media (max-width: 767px) {
	.bottom-30px-md {
		bottom: 30px;
	}
}

@media (max-width: 350px) {
	.bottom-0-sm {
		bottom: 0;
	}
}

.right-0 {
	right: 0px;
}

.right-268px {
	right: 268px;
}

.right-274px {
	right: 274px;
}

@media (max-width: 991px) {
	.right-lg-168px {
        right: 168px;
    }
	.right-lg-173px {
        right: 173px;
    }
}

@media (max-width: 767px) {
	.right-md-23px {
        right: 23px;
    }
	.right-md-28px {
        right: 28px;
    }
}

.left-0 {
	left: 0px;
}

/* Z-Index */
.z-100 {
	z-index: 100;
}



/* --- Sizing ------------------------------------ */

/* Widths */
.w-full {
	width: 100%;
}

@media (max-width: 420px) {
	.w-full-sm {
		width: 100% !important;
    }
}

.w-fit {
	width: fit-content;
}

.w-24px {
	width: 24px;
}

.w-34px {
	width: 34px;
}

.w-40px {
	width: 40px;
}

.w-250px {
	width: 250px;
}

.w-400px {
	width: 400px;
}

.w-600px {
	width: 600px;
}

.w-1000px {
	width: 1000px;
}

.w-1200px {
	width: 1200px;
}

@media (max-width: 1229px) {
	.w-xl-800px {
        width: 800px;
    }
	.w-xl-860px {
        width: 860px;
    }
}

@media (max-width: 991px) {
	.w-lg-600px {
        width: 600px;
    }
}

@media (max-width: 767px) {
	.w-85-md {
        width: 85%;
    }
}


/* Heights */
.h-390px {
	height: 390px;
}


/* --- Spacing ------------------------------------ */

/* Margins */
.m-0 {
	margin: 0;
}


.mx-auto {
	margin-left: auto;
	margin-right: auto;
}

@media (max-width: 767px) {
	.mx-auto-md {
		margin-left: auto !important;
		margin-right: auto !important;
	}
}

.mx-16px {
	margin-left: 1rem; /* 16px */
	margin-right: 1rem; /* 16px */
}

.my-0px {
	margin-top: 0;
	margin-bottom: 0;
}

@media (max-width: 767px) {
	.my-0px-md {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
}

.my-64px {
	margin-top: 4rem; /* 64px */
	margin-bottom: 4rem; /* 64px */
}

.mt-0 {
	margin-top: 0;
}

@media (max-width: 767px) {
	.mt-0-md {
		margin-top: 0 !important;
	}
}

.mt-16px {
	margin-top: 16px;
}

.mt-32px {
	margin-top: 32px;
}

.mt-24px {
	margin-top: 24px;
}

.mt-28px {
	margin-top: 28px;
}

.mt-50px {
	margin-top: 50px;
}

.mt-58px {
	margin-top: 58px;
}

@media (max-width: 767px) {
	.mt-58px-md {
		margin-top: 58px;
	}
}

@media (max-width: 359px) {
	.mt-78px-sm {
		margin-top: 78px;
	}
}

@media (max-width: 226px) {
	.mt-108px-ssm {
		margin-top: 108px;
	}
}

.mt-64px {
	margin-top: 64px;
}

.mt-75px {
	margin-top: 75px;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mb-4px {
	margin-bottom: 4px;
}

.mb-8px {
	margin-bottom: 8px;
}

.mb-16px {
	margin-bottom: 16px;
}

.mb-24px {
	margin-bottom: 24px;
}

.mb-25px {
	margin-bottom: 25px;
}

.mb-35px {
	margin-bottom: 35px;
}

@media (max-width: 767px) {
	.mb-16px-md {
		margin-bottom: 16px !important;
	}

	.mb-25px-md {
		margin-bottom: 25px !important;
	}
}

.mb-50px {
	margin-bottom: 50px;
}

.mb-100px {
	margin-bottom: 100px;
}

.mr-8px {
	margin-right: 8px;
}

.mr-14px {
	margin-right: 14px;
}

@media (max-width: 767px) {
	.mr-8px-md {
		margin-right: 8px;
	}
}

.mr-16px {
	margin-right: 16px;
}

.mr-22px {
	margin-right: 22px;
}

.ml-0 {
	margin-left: 0;
}

@media (max-width: 767px) {
	.ml-0-md {
		margin-left: 0 !important;
	}
}

.ml-8px {
	margin-left: 8px;
}

.ml-321px {
	margin-left: 321px;
}


/* Paddings */
.px-8px {
	padding-left: 8px;
	padding-right: 8px;
}

.px-40px {
	padding-left: 40px;
	padding-right: 40px;
}

@media (max-width: 767px) {
	.px-40px-md {
		padding-left: 40px !important;
		padding-right: 40px !important;
    }
}

@media (max-width: 420px) {
	.px-0px-sm {
		padding-left: 0px !important;
		padding-right: 0px !important;
    }

	.px-20px-sm {
		padding-left: 20px !important;
		padding-right: 20px !important;
    }
}

.py-0 {
	padding-top: 0px;
	padding-bottom: 0px;
}

.py-4px {
	padding-top: 4px;
	padding-bottom: 4px;
}

.py-40px {
	padding-top: 40px;
	padding-bottom: 40px;
}

.pt-8px {
	padding-top: 8px;
}

@media (max-width: 767px) {
	.pt-8px-md {
		padding-top: 8px !important;
    }
	.py-0-md {
		padding-top: 0px !important;
		padding-bottom: 0px !important;
	}
}

.pb-0px {
	padding-bottom: 0;
}

@media (max-width: 767px) {
	.pb-0px-md {
		padding-bottom: 0 !important;
    }
}

.pr-14px {
	padding-right: 14px;
}

@media (max-width: 991px) {
	.pr-8px-lg {
		padding-right: 8px;
    }
}

.pr-45px {
	padding-right: 45px;
}

.pl-14px {
	padding-left: 14px;
}

@media (max-width: 991px) {
	.pl-8px-lg {
		padding-left: 8px;
    }
}

.pl-45px {
	padding-left: 45px;
}


/* --- Border ------------------------------------ */
.border-1px-gray {
	border: 1px solid rgba(128, 128, 128, 0.5) !important;
}

.border-2px-white {
	border: 2px solid rgb(255, 255, 255);
}

.border-b-1px-gray {
	border-bottom: 1px solid rgba(128, 128, 128, 0.3);
}

.border-t-1px-gray {
	border-top: 1px solid rgba(128, 128, 128, 0.3);
}


/* --- Typography ------------------------------------ */

/* Text Alignments */
.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

/* Text Sizes */
.text-15px {
	font-size: 15px;
}

.text-36px {
	font-size: 36px;
}

.text-48px {
	font-size: 48px;
}

/* Font Weights */
.font-bold {
	font-weight: 700;
}


/* Text Colours */
.text-white {
	color: #ffffff;
}

.text-dove-gray {
	color: #676767;
}


/* --- Background Colors ------------------------------------ */
.bg-yellow-200 {
	background-color: #FFF59D;
}

.bg-ocean-green {
	background-color: rgba(76, 159, 128, 0.9);
}

@media (max-width: 767px) {
	.bg-ocean-green-md {
		background-color: #5ca68a;
	}
}
