.site-footer {
	#footer_achievements {
		padding-bottom: 80px;
		p.handwritten {
			color: $greenSecondary;
			font-size: 50px;
			line-height: 1.15;
			margin: 0;
			span { margin-left: 143px; }
		}
		h3 {
			color: $greenSecondary;
			font-size: 12px;
			font-weight: 700;
            margin-bottom: 20px;
			text-transform: uppercase;
		}
		.affiliations, .awards, .suppliers {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
			margin-bottom: 30px;
            img { margin-bottom: 20px; }
		}
		@media only screen and (max-width: 479px) {
			.awards {
				gap: 6px;
				justify-content: flex-start;
			}
		}
	}
	#footer_green {
		background: $greenSecondary;
		padding: 50px 0 30px;
		.container { position: relative; }
		#scroll_top {
			position: absolute;
			right: 15px;
    		top: -72.5px;
		}
		h3 {
			color: $greenSupporting;
			font-size: 15px;
			font-weight: 700;
			margin: 0 0 12px;
		}
		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			li {
				line-height: 1;
				margin-bottom: 12px;
				a {
					color: $white;
					font-size: 15px;
					line-height: 1.3;
					@include touch-hover() { color: $greenSupporting; }
				}
				&.current-menu-item a {
					color: $greenSupporting;
					font-weight: 700;
				}
			}
		}
		.social {
			a {
				color: $greenSupporting;
				display: block;
				font-family: $cursiveFont;
				font-size: 25px;
				svg {
					height: 25px;
					margin-right: 10px; 
					width: 25px;
					path {
						fill: $white;
						@include transition-no-touch(fill ease $animationSpeed);
					}
				}
			}
			a#facebook {
				@include touch-hover() {
					color: $facebook;
					svg path { fill: $facebook; }
				}
			}
			a#linkedin {
				@include touch-hover() {
					color: $linkedin;
					svg path { fill: $linkedin; }
				}
			}
			a#google_plus {
				@include touch-hover() {
					color: $googlePlus;
					svg path { fill: $googlePlus; }
				}
			}
			a#twitter {
				@include touch-hover() {
					color: $twitter;
					svg path { fill: $twitter; }
				}
			}
			a#instagram {
				@include touch-hover() {
					color: $instagram;
					svg path { fill: $instagram; }
				}
			}
		}
		.contact {
			text-align: right;
			p.jumbo {
				color: $white !important;
				font-size: 35px;
				margin-bottom: 15px;
			}
			p {
				color: $greenSupporting;
				font-family: $headingFont;
				font-size: 15px;
				&.small {
					font-size: 10px;
					a {
						font-size: 10px;
						margin-bottom: 0;
					}
				}
			}
			p.address { margin-bottom: 35px; }
			a {
				color: $white;
				display: inline-block;
				font-family: $headingFont;
				font-size: 15px;
				margin-bottom: 25px;
				@include touch-hover() { color: $greenSupporting; }
			}
		}
	}
	.bfa-logo {
		width: 100px;
	}
}
#quote_mobile_form_toggle{ display:none; }

.seperator{
	color:#fff;
}

