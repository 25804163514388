/* ACF Quote Block Styling */
.acf_quote_block {
    background-color: #dfefea;
	margin-top: 16px;
	margin-bottom: 16px;
	padding: 64px 112px;
    text-align: center;
    font-style: italic;
	@media (min-width: 1230px) {
		margin-left: 114px;
    	margin-right: 114px;
	}
	@media (max-width: 767px) {
		margin-right: -15px;
		margin-left: -15px;
	}
	@media (max-width: 600px) {
		padding-top: 64px;
		padding-right: 56px;
		padding-bottom: 64px;
		padding-left: 64px;
	}
}

.acf_quote_block__content {
    position: relative;
	font-family: Arial, Helvetica, sans-serif;
}

.acf_quote_block__mark {
	font-family: Arial, Helvetica, sans-serif;
	color: #4F738A;
    font-size: 5em;
    position: absolute;
    left: -52px;
    top: 6px;
	@media (max-width: 991px) {
		left: -48px;
	}
	@media (max-width: 600px) {
		left: -42px;
		top: 4px;
	}
}

.acf_quote_block__text {
    font-size: 24px;
	line-height: 32px !important;
    color: #4F738A;
	text-align: left;
	@media (max-width: 600px) {
		font-size: 20px;
	}
	margin-bottom: 0;
}
