.franchise-resale-opp-template-default {
	.desktop-title {
		display: none;
	}
	@media (min-width: 991px) {
		.mobile-title {
			display: none;
		}
		.desktop-title {
			display: block;
		}
	}

	.container article h1 {
		color: #4b9f80;
		font-family: Arial, Helvetica, sans-serif;
		font-size: 36px;
		line-height: 40px;
		font-weight: 400;
		margin-top: 64px;
		margin-bottom: 24px;
		padding-left: 128px;
		padding-right: 128px;
		@media (max-width: 1230px) {
			padding-left: 16px;
			padding-right: 16px;
		}
		@media (max-width: 600px) {
			font-size: 30px;
			line-height: 36px;
		}
		text-align: center;
	}
	.container article .wp-paragraph-group p {
		font-size: 18px;
		line-height: 28px;
		padding-left: 128px;
		padding-right: 128px;
		margin-bottom: 15px;
		@media (max-width: 1230px) {
			padding-left: 16px;
			padding-right: 16px;
		}
		@media (max-width: 600px) {
			font-size: 16px;
			line-height: 24px;
		}
	}
	.col-disable {
		width: auto;
		margin-left: 16px;
		margin-right: 16px;
		padding-right: 40px;
		padding-left: 40px;
		@media (max-width: 1230px) {
			padding-left: 16px;
			padding-right: 16px;
		}
	}
	.acf_quote_block {
		margin-top: 32px;
	}
	.intro {
		margin-top: 120px;
		margin-bottom: 120px;
	}
	.intro .twocolumn {
		padding-right: 20px;
		padding-left: 20px;
		@media (max-width: 991px) {
			padding-left: 16px;
			padding-right: 16px;
		}
		@media (max-width: 300px) {
			padding-left: 4px;
			padding-right: 4px;
		}
		h4 {
			font-size: 20px;
			@media (max-width: 1230px) {
				text-align: left;
			}
			@media (max-width: 300px) {
				font-size: 18px;
			}
		}
		p {
			font-size: 18px;
			line-height: 28px;
			@media (max-width: 1230px) {
				text-align: left;
			}
			@media (max-width: 400px) {
				font-size: 16px;
				line-height: 24px;
			}
		}
	}


	/* Gravity Forms Styling */
	#gform_wrapper_19 {
		background-color: #a8dccd;
		padding: 80px 96px;
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;
		margin-top: 96px;
		margin-bottom: 96px;
		@media (max-width: 991px) {
			padding: 80px 64px;
		}
		@media (max-width: 767px) {
			padding: 48px 36px;
		}
		@media (max-width: 400px) {
			padding: 40px 12px;
		}

		.gravity-theme .gform_body {
			padding: 0 !important;
		}
		.gfield {
			padding: 0 !important;
		}

		.gform_heading .gform_title {
			color: #00721A;
			font-family: Arial, Helvetica, sans-serif;
			font-size: 24px;
			font-weight: 700;
			margin-bottom: 20px;
		}

		.gform-body .gravity_forms_heading {
			color: #00721A;
			font-family: Arial, Helvetica, sans-serif;
			font-size: 24px;
			line-height: 32px;
			font-weight: 700;
		}

		.gfield_label {
			color: #00721A;
			font-size: 16px;
		}

		.gfield input[type=text],
		.gfield input[type=email],
		.gfield textarea {
			width: 100%;
			padding: 10px;
			border-radius: 5px;
			border: 1px solid #ccc;
		}

		.gform_footer input[type=submit] {
			background-color: #00721A;
			color: #fff;
			padding: 12px 20px;
			border-radius: 0;
			border: none;
			cursor: pointer;
			margin-left: 16px;
			margin-top: -14px;
			@media (max-width: 641px) {
				padding: 6px 16px;
				margin-top: -8px;
			}
		}

		.gform_footer input[type=submit]:hover {
			background-color: #fff;
			color: #00721A;
		}
		.gform_footer input[type=submit]:active {
			transform: scale(0.95);
			color: #00721A;
			background-color: whitesmoke;
		}

		#field_submit {
			flex-direction: row;
		}

		.gform-field-label {
			color: #00721A;
		}

		.gform_fields {
			grid-column-gap: 32px !important;
			@media (max-width: 991px) {
				grid-column-gap: 4% !important;
				grid-row-gap: 24px !important;
			}
			@media (max-width: 641px) {
				display: flex;
				flex-direction: column;

				.gf-field__name {
				  order: 1;
				}
			  
				.gf-field__email {
				  order: 2;
				}
			  
				.gf-field__phone {
				  order: 3;
				}
			  
				.gf-field__street {
				  order: 4;
				}
			  
				.gf-field__city {
				  order: 5;
				}
			  
				.gf-field__postcode {
				  order: 6;
				}

				.checkbox_franchisee-contact {
					order: 7;
				}

				.checkbox_privacy-policy {
					order: 8;
				}
			}
		}
		.checkbox_franchisee-contact .gfield_consent_label,
		.checkbox_privacy-policy .gfield_consent_label {
			font-size: 15px;
		}
		.checkbox_franchisee-contact {
			margin-top: 8px;
		}
		.checkbox_privacy-policy {
			margin-top: -16px;
		}
	}

	.container #gform_confirmation_message_18 {
		background-color: #a8dccd !important;
		text-align: center !important;
		color: #00721A !important;
		font-size: 18px !important;
		font-weight: 700 !important;
	}

	// Two Column Text Styling
	.intro .twocolumn-1 {
		padding-right: 16px;
		padding-left: 112px;
		@media (max-width: 1230px) {
			padding-left: 96px;
		}
		@media (max-width: 991px) {
			padding-right: 32px;
			padding-left: 32px;
		}
		@media (max-width: 600px) {
			padding-right: 16px;
			padding-left: 16px;
		}
	}
	.intro .twocolumn-2 {
		padding-right: 142px;
		padding-left: 16px;
		@media (max-width: 1230px) {
			padding-right: 126px;
		}
		@media (max-width: 991px) {
			padding-right: 32px;
			padding-left: 32px;
		}
		@media (max-width: 600px) {
			padding-right: 16px;
			padding-left: 16px;
		}
	}
}
