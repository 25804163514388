/* ACF Squares Block Styling */
.acf_squares_block {
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 16px;
	margin-bottom: 32px;

	.acf_squares_block__heading {
		color: #4b9f80;
		font-weight: 400;
		font-family: Arial, Helvetica, sans-serif;
		font-size: 30px;
		line-height: 36px;
		text-align: center;
		margin-bottom: 16px;
		@media (max-width: 767px) {
			font-size: 24px;
			line-height: 32px;
		}
		@media (max-width: 400px) {
			font-size: 20px;
			line-height: 28px;
		}
	}
	
	.acf_squares_block__sub-heading {
		font-size: 18px;
		line-height: 28px;
		text-align: center;
		@media (max-width: 576px) {
			font-size: 16px;
			line-height: 24px;
		}
	}
	
	@media (max-width: 767px) {
		.acf_squares_block__heading {
			font-size: 24px;
		}
	}
	
	.acf_squares_block__grid {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		gap: 40px 20px;
		justify-items: center;
		margin-top: 16px;
		margin-bottom: 16px;
		@media (min-width: 992px) {
			grid-template-columns: repeat(4, 1fr);
		}
		@media (max-width: 767px) {
			max-width: 460px;
			margin: 0 auto;
		}
		@media (max-width: 495px) {
			gap: 28px;
		}
	}
	
	.acf_squares_block__item {
		width: 100%;
		display: flex;
		justify-content: center;
	}
	
	.acf_squares_block__square {
		width: 200px;
		height: 200px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		background-color: #ffffff;
		box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
		filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.05));
	
		@media (max-width: 576px) {
			width: 100%;
			height: auto;
		}
	}
	
	.acf_squares_block__square img {
		margin-top: 30px;
	}
	.acf_squares_block__square p {
		font-size: 12px;
		font-family: Arial, Helvetica, sans-serif;
		font-weight: 600;
		padding: 8px;
	}
	
	.acf_squares_block__disclaimer {
		margin: 24px;
		p {
			text-align: center;
			font-size: 0.8em;
			margin-bottom: 0;
		}
	}	
}
