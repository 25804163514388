.site-header {
    .form-wrapper.active { right: -100px; }
    .form-toggle span.handwritten { margin-right: -60px; }
}

.banner {
    .banner-title-wrapper { top: 50px; }
    .banner-title h1, .banner-title p { font-size: 40px; }
}

.intro h1, .intro h2 {
    margin-left: 90px;
    margin-right: 90px;
}

body.page-id-17, body.page-id-32 {
    .intro a.button-link { top: 0; }
}

body.page-id-30 {
    .intro { margin-bottom: 30px; }
    .flexible-content {
        .sr-left ul, .sr-right ul {
            margin-bottom: 50px;
            li {
                img {
                    top: -5px;
                    transform: translateY(50%);
                    -ms-transform: translateY(50%);
                }
                h3 { font-size: 21px; }
            }
        }
        .text-image-row {
            margin-bottom: 50px;
            h4 {
                font-size: 24px;
                margin-top: 0;
            }
        }
    }
}

.flexible-content {
    .sr-left, .sr-right {
        h3 { margin-bottom: 10px; }
        ul { margin: 20px 0 30px; }
    }
}

.post-card {
    height: 316px;
    .post-detail { width: 380px; }
}

body.page-template-page-success-stories {
	.cards {
		.card {
			.card-detail {
				height: 384px !important;
			}
			@include touch-hover() {
				.card-detail { height: 384px !important; }
			}
		}
	}
}

.post-card-fw .post-detail { width: auto; }

.owl-carousel .post-card {
    height: 316px;
    margin-top: 30px;
    overflow: hidden;
    .post-detail {
        width: 380px;
        p.type { font-size: 16px; }
        p.name { font-size: 20px; }
        p.date {
            font-size: 12px;
            right: 38px;
        }
    }
}

body.page-id-77 .pai-posts a.button-link { margin-bottom: 0; }

.ctas .cta .cta-message p { font-size: 21px; }

#local_operator_cta .form-wrapper form {
    input[type=text] { margin-bottom: -15px; }
    input[type=submit] { padding: 10px 20px; }
}

.full-width-ctas .cta {
    img {
        height: auto;
        width: 640px;
    }
    .cta-message {
        p.title { font-size: 21px; }
        p { font-size: 13px; }
    }
    a.button-link-svg {
        font-size: 12px;
        padding: 10px 35px 10px 20px;
        svg {
            height: 10px;
            width: 10px;
        }
    }
}

.testimonials {
    .testimonial-block .testimonial-wrapper {
        width: 445px;
        .quote { font-size: 15px; }
        .img-wrapper {
            top: 75px;
            border-radius: 45px;
            -webkit-border-radius: 45px;
            -moz-border-radius: 45px;
            img {
                border-radius: 45px;
                -webkit-border-radius: 45px;
                -moz-border-radius: 45px;
                height: 90px;
                width: 90px;
            }
        }
    }
    .left-testimonial {
        img#quote_mark { right: 450px; }
        .testimonial-wrapper {
            .quote, p.quotee { padding-right: 100px; }
        }
    }
    .right-testimonial {
        img#quote_mark { left: 450px; }
        .testimonial-wrapper {
            .quote, p.quotee { padding-left: 100px; }
        }
    }
}

.tab-control {
	font-size: 11px !important;
	padding: 8px 15px !important;
}

.categories a.button-link { width: 23.75%; }

body.archive, body.category, body.tag {
    .news-posts .row { margin-bottom: 0; }
    .post-card {
        height: 450px;
        margin: 0 0 30px;
    }
}

body.page-id-4587 .post-card { margin: 0 0 30px; }

.map { min-height: 684px; }

body.single-area {
    .intro {
        margin-bottom: 30px;
        a.button-link { top: 85px; }
    }
    .region-map .areas-panel-wrapper .areas-panel { font-size: 24px; }
}

.contactMap{
    height: 790px;
    width: 100%;
}

body.page-id-4817 #map{ height:790px; }

.operatorSubmitHolder{
    svg{
        height: 10px;
        width: 10px;
        top: 20px;
    }
}
body.page-id-4806 {
    .handwrittenWrapper{ text-align:right;}
}

.wpcf7-mail-sent-ng{
    width: 60%;
    bottom:10px !important;
}

body.page-template-page-signmaster form .wpcf7-mail-sent-ng{
    width:initial;
}

body.page-template-page-download_prospectus_2{
    .banner-title-wrapper{
        position:absolute !important;

    }
    .banner{
        max-height: initial !important;
        margin-bottom: 80px;
    }
    .banner img{
        min-height: 650px;
    }
}

body.page-id-26 .cards {
    .card .card-detail { min-height: 338px; }
    .signmaster-card .card-detail img { margin-top: 0; }
}

iframe {
    height: 260px;
    width: 450px;
}

body.page-template-page-about .cards .team-member-card {
	.card-detail {
		height: 290px !important;
		.social-links {
			bottom: 15px;
			opacity: 1;
		}
	}
}

.awards .award-item {
    width: 12%;
    &:first-of-type {
		text-align: left;
		width: 10%;
	}
	&:last-of-type {
		text-align: right;
		width: 10%;
	}
	&.wide {
		width: 19%;
	}
}

.gallery .modal .modal-image-wrapper iframe {
    height: 475px;
    width: 840px;
}
.panel-form .wpcf7-response-output{
    bottom:-10px;
}
.page-id-4296 .wpcf7-response-output,
.page-id-4298 .wpcf7-response-output,
.page-id-4294 .wpcf7-response-output,
.page-id-4300 .wpcf7-response-output{
    bottom:30px;
}
