.flex {
	display: flex;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.flex-col {
	display: flex;
	flex-direction: column;
}

.w-full {
	width: 100%;
}

.w-43 {
	width: 680px;
}

.h-56 {
	height: 224px;
}
.h-52 {
	height: 208px;
}

.h-72 {
	height: 288px;
}

.f-48 {
    font-size: 48px;
}

@media only screen and (max-width: 479px) {
	.f-xs-28 {
        font-size: 28px;
    }
}

.text-center {
    text-align: center;
}

.img-fluid {
    max-width: 100%;
    height: auto;
    min-height: 1px;
}

.p-0 {
	padding: 0;
}

.px-2 {
	padding-left: 0.8rem; /* 8px */
	padding-right: 0.8rem; /* 8px */
}

.px-3 {
	padding-left: 1.2rem; /* 12px */
	padding-right: 1.2rem; /* 12px */
}

.px-4 {
	padding-left: 1.6rem; /* 16px */
	padding-right: 1.6rem; /* 16px */
}

.mx-2 {
	margin-left: 0.8rem; /* 8px */
	margin-right: 0.8rem; /* 8px */
}

.my-16 {
	margin-top: 5rem; /* 50px */
	margin-bottom: 5rem; /* 50px */
}

.mb-3 {
	margin-bottom: 2.8rem; /* 28px */
}

.mb-5 {
    margin-bottom: 5rem;
}

.mb-8 {
    margin-bottom: 8rem;
}

@media (min-width: 1230px) {
	.-mb-16 {
		margin-bottom: -64px !important;
	}
}

.leading-tight {
    line-height: 1.25;
}

.flex-wrap {
	flex-wrap: wrap;
}

@media (max-width: 1229px) {
	.flex-wrap-mobile {
	  flex-wrap: wrap !important;
	  justify-content: center;
	}
}

.square_box-shadow {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);

	@media (max-width: 340px) {
		width: 100%;
		min-height: auto !important;
	}
}
