form {
    input[type="text"], input[type="email"], textarea {
    	border: 0;
        border-radius: 0;
    	display: block;
    	font-size: 15px;
    	margin-bottom: 15px;
    	padding: 7px 12px;
    	width: 100%;
        &.wpcf7-not-valid { border: 2px solid $redSecondary; }
    }
    input[type="text"].small { width: 200px; }
    input[type="submit"] {
    	background: none;
    	border-color: $white;
        border-radius: 0;
    	color: $white;
        @include touch-hover() {
            background: $white;
            color: $greenSecondary;
            text-decoration: none !important;
        }
    }
    input[type="text"], input[type="email"] { height: 40px; }
    textarea { height: 160px; }
    select {
        height: 40px;
        width: 100%;
    }
    fieldset {
        border: 0;
        margin: 0;
        padding: 0;
        legend {
            color: $white;
            font-weight: 700;
            margin-bottom: 5px;
        }
        .form-field:last-child {
            input { margin-bottom: 0; }
        }
    }

    ::-webkit-input-placeholder { font-style: italic; }
    :-moz-placeholder { font-style: italic; }
	::-moz-placeholder { font-style: italic; }
	:-ms-input-placeholder { font-style: italic; }
}

div.wpcf7-response-output {
    background: rgba(255,255,255,.9);
    color: $greenSecondary;
    font-family: $headingFont;
    font-weight: 700;
    line-height: 1.6;
    margin: 30px 0;
    padding: 10px 15px;
    text-align: center;
}

form#operator_search { margin-bottom: 40px; }
.headerForm .form-field input[type="text"],
.headerForm .form-field input[type="email"],
.headerForm .form-field input[type="number"],
.headerForm .form-field textarea, 
.headerForm .form-field select{
    color:#000;
}
.wpcf7 form {
    h2, p { text-align: left; }
    h2 { margin: 0 0 10px; }
    p { margin-bottom: 30px; }
    .form-field {
        p.handwritten {
            color: $white;
            display: inline-block;
            font-size: 28px;
            margin-top: 5px;
        }
        p {
            display: inline;
            margin-bottom: 0;
        }
        input[type="submit"] {
            float: right;
            margin: 5px 0 30px;
        }
    }
}

div.wpcf7 .wpcf7-form .form-field img.ajax-loader, span.wpcf7-not-valid-tip { display: none; }

div.wpcf7-validation-errors {
    border: 0 !important;
    color: $redSecondary;
}

span.wpcf7-not-valid-tip{
    display:none !important;
}

textarea:focus, input:focus, textarea:active, input:active, select:focus, select:active {outline:0 !important;}

.form-row{
    z-index: 2;
}

.panel-form .wpcf7-response-output{
    position: absolute;
    display: block;
    bottom: 10px;
    left:7px;
    display:none;
}

.page-template-page-download_prospectus .form-panel form .wpcf7-response-output{
    margin-top:0px;
    margin-bottom:0px;
}

.page-template-page-thank-you-for-your-enquiry .form-panel form .wpcf7-response-output{
    margin-top:0px;
    margin-bottom:30px;
    margin-left:15px;
    margin-right:15px;
}

.single-area form .wpcf7-response-output{
    margin-top:40px;
    margin-bottom:30px;
    margin-left:10px;
    margin-right:15px;
    bottom:15px;
}

.page-template-page-download_prospectus_2 .wpcf7 form {
    input[type="text"], input[type="email"] { color: #333; }
    .form-field input[type=submit] { margin-bottom: 15px; }
}

.headerForm{
    .form-field{
        .wpcf7-acceptance{ margin-right:10px; }
        color:#fff !important;
        a{
            color:#fff;
        }
    }
}
.form-field{
    input[type="checkbox"]{ margin-right:10px; }
    color:#fff !important;
    a{
        color:#fff;
    }
    span.wpcf7-list-item{
        margin:0px !important;
    }
}
.handwrittenWrapper{
    margin-top: 1rem;
}

.wpcf7-form-control{
    line-height:1.6;
}
input:not([type="submit"]), optgroup, select, textarea{
    color:#000 !important;
}

.error-alert{
    border-bottom: 2px solid red; 
    padding-bottom: 2px;
}
.page-id-4296 .wpcf7-response-output,
.page-id-4298 .wpcf7-response-output,
.page-id-4294 .wpcf7-response-output,
.page-id-4300 .wpcf7-response-output{
    bottom:20px;
}
.wpcf7-acceptance{
    label{
        span{
            a{
                font-weight: bolder;
                &:hover{
                    color:$greenSupporting;
                }
            }
        }
    }
}

#faq-complaint-link:hover {
	svg.face-frown-icon {
		fill: black;
	  }
	  .text-color-dove-gray {
		color: black;
	}
}

#complaint-form {
	input:not(.complaint-checkbox) {
		height: 40px;
		width: 400px;
		margin-top: 8px;
		margin-bottom: 16px;
		border: 1px solid rgba(128, 128, 128, 0.5) !important;

		@media (max-width: 420px) { 
			width: 100% !important;
		}
	}

	input[type="tel"] {
		width: 250px;
		margin-top: 8px;
		margin-bottom: 16px;
		padding-left: 12px;
		padding-right: 12px;
	}

	input[type="tel"]::placeholder {
		font-size: 15px;
	}
	
	textarea {
		width: 400px;
		height: 434px;
		margin-top: 8px;
		resize: none;
		border: 1px solid rgba(128, 128, 128, 0.5) !important;
	}
	
	input:hover, textarea.complaint-text:hover,
	input:focus, textarea.complaint-text:focus {
		border: 1px solid black !important;
	}
	
	.complaint-form-button {
		color: white;
		background-color: #5ca68a;
		padding: 16px 32px;
	}
	.complaint-form-button:hover {
		background-color: #ffffff;
		color: #5ca68a;
	}
	.complaint-form-button:focus {
		top: 0.1em;
	}

	.complaint-text:focus {
		border: 1px solid black;
	}

	@media (max-width: 991px) {
		input:not(.complaint-checkbox),
		.complaint-text {
			width: 325px;
			
			@media (max-width: 420px) { 
				width: 100% !important;
			}
		}
	}
}
