/*====  Typography  ====*/

/*  Fonts  */
//@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700);
//@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,700);
//@import url(https://fonts.googleapis.com/css?family=Loved+by+the+King);


/*  Variables  */
$headingFont: 'Lato', sans-serif;
$bodyFont: 'Open Sans', sans-serif;
$cursiveFont: 'Loved by the King', cursive;


/*  Elements  */
body {
    font-family: $bodyFont;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.875;  /* Based on the ideal line height of 30px for a 1280px wide body element with a font size of 16px (see http://www.pearsonified.com/typography/) */
}

h1, h2, h3, h4, h5, h6 {
    color: $greenSecondary;
    font-family: $headingFont;
    font-weight: 400;
    line-height: 1.25;
    margin: 25px 0 15px;
}

h1 { font-size: 48px; }

h2 { font-size: 40px; }

h3 {
    font-size: 32px;
    margin: 0 0 10px;
}


p {
	color: $lightGrey;
	line-height: 1.75;
	margin-top: 0;
}

p.jumbo {
    font-family: $headingFont;
    font-size: 35px;
    font-weight: 700;
    line-height: 1;
}

p.address { font-family: $headingFont; }

.handwritten { font-family: $cursiveFont !important; }

ul li { color: $lightGrey; }

blockquote {
    margin: 30px 0;
    p {
        margin: -35px 0 30px;
        padding-left: 40px;
    }
    &:before { content: url(/wp-content/themes/agency-express/images/quote-mark-green.png); }
}

.blueHeader{ color: $paiBlue; }
.center{ text-align:center;}

.centeredonecolumn{
    text-align:center;
    p{
        text-align:center;
    }
}
.two-column-text ul li{
    color: #676767;
}

.single-post {
	.post-content {
		margin-bottom: 50px;
		h1, h2 {
			margin: 36px 0 24px;
		}
		h1 { font-size: 32px; }
		h2 { font-size: 26px; }
		h3 { font-size: 22px; }
		h4 { font-size: 20px; }
		h5 { font-size: 18px; }
		h6 { font-size: 16px; }
	}
}
