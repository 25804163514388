.main-accordion-container {
	width: 80%;
	max-width: 1000px;
	margin: 0 auto;
}

.accordion-container {
	margin-bottom: 30px;
}

button.accordion {
	width: 100%;
	height: 80px;
	background-color: white;
	border: none;
	outline: none;
	text-align: left;
	text-transform: none !important;
	padding: 30px 30px 15px 30px;
	font-size: 2rem;
	color: #707070;
	cursor: pointer;
	transition: background-color 0.2s linear;
	box-shadow: 0px 0px 6px -1px rgb(0 0 0 / 0.1), 0px 5px 4px -2px rgb(0 0 0 / 0.1);
}

button.accordion:after {
	content: "\2303";
	position: relative;
	top: -7px;
	transform: rotate(180deg);
	font-size: 28px;
	float: right;
	transition: all .35s;
}

button.accordion.is-open:after {
	/* content: "\2212"; */

	transform: rotate(0deg);
}

button.accordion:hover,
button.accordion.is-open {
	color: white;
	background-color: #619D81;
	box-shadow: none;
}

.accordion-content {
	background-color: #619D81;
	padding: 0 30px;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.2s ease-in-out;
	color: white;
}

.accordion-content a:link {
	color: white;
	text-decoration: underline;
}

.accordion-content a:visited,
.accordion-content p {
	color: white;;
}

.accordion-content a:hover {
	color: #dcdcdc;
	text-decoration: underline !important;
	transition: color 0.3s ease-in-out;
}

.accordion-content > p:last-child {
	margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
	button.accordion {
		font-size: 1.7rem;
	}
	button.accordion:after {
		font-size: 24px;
	}
}
