/*  Other Variables  */
$animationSpeed: 700ms;


/* Utility Classes */
.img-responsive {
	display: block;
	height: auto;
	max-width: 100%;
}

.two-column-text {
	column-count: 2;
	column-gap: 30px;
}

.left-one-column{
	text-align:left;
	p{
		text-align:left !important;
	}
	h2{
		margin-top: 50px;
		margin-right: 0px !important;
		margin-bottom: 35px;
		margin-left: 0px !important;
		text-align:left !important;
	}
}

/*  The <body> Element  */
body {
	&.disable-scroll {
		overflow-x: hidden;
	    overflow-y: hidden;
	}
	&.admin-bar {
		.warning-covid19 { top: 32px; }
		.site-header { top: 32px; }
		.site-header.notification-offset { top: 75px; }
		div#main { padding-top: 156px; transition: all ease .3s; }
		@media (max-width: 767px) {
			div#main {
				padding: 95px 0px 0px 0px;
				transition: all ease .3s;
			}
		}
	}
}

.clearfix{
	height: 0px;
	clear: both;
}