.sm-hidden { display: none; }

.site-header {
	.form-wrapper.active { right: -180px; }
	.form-toggle span.handwritten {
		font-size: 18px;
		margin-right: -45px;
		margin-top: -5px;
	}
	&.minified .form-toggle span.handwritten { font-size: 18px; }
	.menu-wrapper.active { left: -180px; }
}

.banner {
	.banner-title-wrapper { top: 40px; }
	.banner-title h1, .banner-title p { font-size: 28px; }
}

.intro h1, .intro h2 {
	margin-left: 30px;
	margin-right: 30px;
}

#pai_signup_form {
	height: auto;
	p { text-align: center; }
	input[type=email] { margin: 20px 0 0; }
	input[type=submit] { margin: 15px 0 0; }
}

.flexible-content {
	.sr-left, .sr-right {
		img {
			margin: 0 auto 40px;
			max-width: 500px;
		}
		h3, p { text-align: center; }
		h3 { margin-bottom: 15px; }
		ul {
			margin: 30px 0 40px;
			li {
				img { width: auto; }
				p { text-align: left; }
			}
		}
		a.button-link {
			left: 50%;
			transform: translateX(-50%);
			-ms-transform: translateX(-50%);
		}
	}
	.twocolumn {
		&:first-child { margin-bottom: 0 !important; }
		p { text-align: center; }
	}

}

.wpcf7 form h2, .wpcf7 form p { text-align: center; }

body.page-id-4298, body.page-id-4300 {
	.flexible-content .text-image-row { margin-bottom: 70px; }
}

body.page-id-17, body.page-id-32 {
	.intro {
		img { width: 250px; }
		a.button-link { top: -8px; }
	}
	.form-row {
		.form-dismiss { top: 52px; }
	}
	.flexible-content .text-image-row {
		img, video { margin-bottom: 50px; }
		p.button-wrapper {
			display: block;
			margin-top: 50px;
			position: relative;
			a.sm4-form-trigger {
				left: 15px;
				margin-left: 0;
				position: relative;
				right: initial;
				transform: none;
				-ms-transform: none;
			}
		}
	}
}

body.page-id-30 .flexible-content {
	.sr-left ul li, .sr-right ul li {
		p { font-size: 15px; }
		h3 {
			line-height: 1.3;
			text-align: left;
		}
	}
	.text-image-row h4 { text-align: center; }
}

body.page-id-17 .flexible-content .text-image-row img { margin-bottom: 0; }

body.page-id-4294 .cards { margin-bottom: 60px; }

.post-card {
	height: 242px;
	.post-detail {
		width: 100%;
		.post-detail-content { padding: 15px 20px 15px 40px; }
		p.name { font-size: 18px; }
		p.date { right: 20px; }
	}
}



.post-card-fw .post-detail {
	width: 550px;
	p.type { margin-bottom: 5px; }
	p.name {
		font-size: 20px;
		line-height: 1.25;
	}
}

.slider-wrapper .owl-carousel .post-card {
	height: 242px; 
	.post-detail {
		width: 100%;
		p.date { right: 20px; }
	}
}

.statistics .stat {
	span { font-size: 40px; }
	p { font-size: 15px; }
}

.ctas .cta {
	.cta-message {
		right: 15px;
		top: 15px;
		padding: 10px 20px;
		p { font-size: 18px; }
	}
	a.button-link-svg {
		bottom: 15px;
		font-size: 12px;
		padding: 10px 40px 10px 20px;
		right: 15px;
		svg {
			height: 12px;
			width: 12px;
		}
	}
}

#local_operator_cta .form-wrapper {
	bottom: 5px;
	right: 15px;
	form input[type=text] {
		height: 35px;
		margin-bottom: -17px;
	}
	input[type=submit] { font-size: 10px; }
}

.full-width-ctas .cta {
	img { width: 100%; }
	.cta-message {
		padding: 20px 30px 30px;
		position: relative;
		width: 100%;
		p {
			font-size: 16px;
			text-align: center;
		}
	}
	a.button-link {
		bottom: 20px;
		display: block;
		margin: 0 auto;
		position: relative;
		right: initial;
		width: 145px;
	}
}

.tab-content { column-count: 1 !important; }

.tab-control {
	font-size: 10px !important;
	padding: 8px !important;
}

.tab-down-arrow-btn {
	right: 10px !important;
	width: 10px !important;
}

.categories a.button-link {
	width: 48.9%;
	&:nth-child(2) { margin: 0 0 15px; }
}

.site-footer {
	#footer_achievements {
		padding-bottom: 40px;
		p.handwritten {
			font-size: 36px;
            margin-bottom: 20px;
			span { margin-left: 100px; }
		}
		h3 { text-align: center; }
	}
	#footer_green {
		.social {
			margin: 20px 0 30px;
			text-align: center;
			a {
				display: inline-block;
				margin-right: 30px;
				&:last-child { margin-right: 0; }
			}
		}
		.contact {
			text-align: center;
			p.jumbo, p.address, a { margin-bottom: 10px; }
			p.small { margin-bottom: 20px; }
		}
	}
}

body.archive, body.category, body.tag {
	.post-card { height: 345px; }
}

body.page-id-1837 {
	.intro { margin-bottom: 0; }
	.faqs {
		h2 { font-size: 28px; }
		h3 { font-size: 21px; }
	}
}

body.page-id-4531 {
	.spotlight h2 { font-size: 36px; }
	.post-card-fw .post-detail {
		height: 105px;
		width: 420px;
		p.location, p.name { font-size: 28px; }
	}
}

body.single-area .region-map .areas-panel-wrapper .areas-panel {
	font-size: 21px;
	padding: 15px 30px;
	p { margin-bottom: 10px; }
}

body.page-id-4817 {
	.map {
		min-height: 880px;
	}
}
.contactMap{
	height: 790px;
	width: 100%;
}

body.page-id-4817 #map{ height:990px; }

body.page-id-77 {
	.intro {
		img { width: 250px; }
	}
}

.operatorSubmitHolder{
	svg{
		height: 10px;
		width: 10px;
		top: 17px;
	}
}

body.page-id-4806 {
	.handwrittenWrapper{ text-align:right;}
}

.faqs{
	h4{ font-size: 18px; }
}

.twocolumn, .intro{
	text-align:center;
	form{ text-align:left; }
	p{
		text-align:center;
	}
}


.quote-form-mobile{
	display:none;
}

/*form styles*/
.panel-form .wpcf7-response-output{
	position: relative;
	display: block;
	margin-left: 18.7%;
	margin-right: 18.7%;
	left:0px;
}

#pai_signup_form {
	.wpcf7-response-output{
		margin-top:25px;
		margin-left: 10.5%;
		margin-right: 10.5%;
	}
}

.panel-signmaster-form{
	.wpcf7-response-output{
		margin-top: 0px;
		margin-left:0px;
		margin-right:0px;
		position:initial;
		display:none;
	}
}

.page-template-page-download_prospectus .form-panel form .wpcf7-response-output{
	margin-left: 17.3%;
	margin-right: 17.3%;
}

.page-template-page-thank-you-for-your-enquiry .form-panel form .wpcf7-response-output{
	margin-left: 17.3%;
	margin-right: 17.3%;
}

.single-area form .wpcf7-response-output{
	display:none;
	margin-left: 18.3%;
	margin-right: 19.7%;
	margin-top: 10px;
}
.single-area form select{
	margin-bottom:15px;
}

.wpcf7-mail-sent-ng{
	width: initial;
}

body.page-template-page-download_prospectus_2{
	.banner-title-wrapper {
    position: absolute !important;
		.banner-title {
			min-height: 275px;
			min-width: 0;
		}
	}
	.banner{
		max-height:none !important;
		margin-bottom: 60px;
	}
	.banner img {
		min-height: initial;
	}
}

body.page-template-page-download_prospectus_2{
	.banner-title{
		right:initial;
		padding: 15px 15px;
		position:relative !important;
		margin:0px !important;
		.form-holder{ width:auto !important; }
	}
}

body.single-post .pagination, body.single-pai .pagination, .pagination {
	.controls {
		margin-bottom: 10px;
		.prev, .next {
			width: 50%;
			svg {
				position: absolute;
				top: 2px;
				width: 15px;
			}
			a {
				display: block;
				font-size: 16px;
			}
		}
		.prev {
			padding-right: 10px;
			a { padding-left: 20px; }
		}
		.next {
			padding-left: 10px;
			text-align: right;
			svg { right: 0; }
			a { padding-right: 20px; }
		}
	}
	a.button-link {
		line-height: 1.4;
		margin-top: 30px;
		padding: 10px 40px;
		width: 240px;
		&-svg { margin-top: 40px; }
	}
}

.banner .banner-title .download-link {
	bottom: 0;
	left: 0;
	margin: 15px auto;
	position: relative;
}

.testimonials {
	.testimonial-block {
		min-height: 370px;
		img#quote_mark { top: 20px; }
		.testimonial-wrapper {
			padding-top: 20px;
			width: 345px;
			.quote {
				font-size: 14px;
				line-height: 1.7;
			}
		}
	}
	.left-testimonial img#quote_mark { right: 350px; }
	.right-testimonial img#quote_mark { left: 350px; }
}

blockquote {
	p {
		margin: 30px 0;
		padding-left: 0;
	}
	&:before { float: left; }
}

body.single-pai, body.single-post {
	.intro ul {
		list-style: none;
		margin: 0 0 30px;
		padding-left: 0;
	}
}

.two-column-text {
	column-count: 1;
    column-gap: 0;
    p { text-align: center; }
}

iframe {
	height: 315px;
	width: 560px;
}

body.page-template-page-about .cards .team-member-card {
	.card-detail { height: 340px !important; }
	@include touch-hover() {
		.card-detail { height: 340px !important; }
	}
}

body.page-template-page-success-stories .cards .card {
	.card-detail { height: 432px !important; }
	@include touch-hover() {
		.card-detail { height: 432px !important; }
	}
}

.gallery {
	margin-top: 30px;
	text-align: center;
	.gallery-image-wrapper {
		display: inline-block;
		margin-bottom: 15px;
		img { margin-bottom: 0; }
	}
	.modal .modal-image-wrapper iframe {
	    height: 350px;
	    width: 620px;
	}
}

.tab-link:last-child .tab-control {
	text-align: left;
	padding-left: 15px !important;
}

.awards .award-item{
    width: 10.4%;
}

.awards .award-item {
	text-align: center;
    width: 24%;
	&:first-of-type {
		text-align: center;
		width: 24%;
	}
	&:last-of-type {
		text-align: center;
		width: 24%;
	}
	&.wide {
		width: 30%;
	}
}

.panel-form .wpcf7-response-output{
    bottom:10px;
}
.page-id-4296 .wpcf7-response-output,
.page-id-4298 .wpcf7-response-output,
.page-id-4294 .wpcf7-response-output,
.page-id-4300 .wpcf7-response-output{
    bottom:35px;
}