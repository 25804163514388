body.page-id-32,
body.page-id-17 {
	.site-header {
		background: $blueSupporting;
		.form-toggle {
			#quote_link, #close {
				@include touch-hover() { 
					color: $lightBlueSupporting !important; 
					svg {
						path, rect { fill: $lightBlueSupporting !important; }
					}
				}
			}
		}
		.form-wrapper, .menu-wrapper { background: $blueSupporting; }
		.menu-toggle {
			#menu, #close {
				@include touch-hover() {
					color: $lightBlueSupporting !important; 
					svg {
						path, rect { fill: $lightBlueSupporting !important; }
					}
				}
			}
		}
		.menu-wrapper ul li {
			&.current-menu-item a { color: $lightBlueSupporting !important; }
			a {
				@include touch-hover() { color: $lightBlueSupporting !important; }
			}
		}
		&.minified {
			background: rgba(84,128,177,.9);
			.form-wrapper, .menu-wrapper { background: rgba(84,128,177,.9); }
		}
	}
	.quote-form-desktop .form-toggle #close {
		@include touch-hover() {
			color: $lightBlueSupporting !important; 
			svg {
				path, rect { fill: $lightBlueSupporting !important; }
			}
		}
	}
	.banner .banner-title h1 span { color: $blueSupporting; }
	.intro {
		position: relative;
		img {
			display: block;
			width: 384px;
			margin: 50px auto 35px;
		}
		a.button-link { top: 50px; }
		a {
			color: $blueSupporting;
			@include touch-hover() { color: $lightBlueSupporting; }
		}
	}
	.form-row {
		background: rgba(84,128,177,.9);
		padding-top: 0;
		img#sm4_logo, img#devices { margin: 50px auto 35px; }
		.form-dismiss {
			right: 15px;
    		top: 60px;
		}
		p { text-align: left; }
		p.title {
			font-size: 24px;
			font-weight: 700;
			margin-bottom: 0;
			text-transform: uppercase;
		}
		#sm4_logo {
			width: 385px;
			bottom: -16px;
		}
	}
	a.button-link {
		border-color: $blueSupporting;
		color: $blueSupporting;
		height: 49px;
		position: absolute;
    	right: 15px;
    	svg path { fill: $blueSupporting; }
    	@include transition-no-touch(all ease $animationSpeed);
		@include touch-hover() {
			background: $blueSupporting !important;
			color: $white;
			text-decoration: none !important;
			svg path { fill: $white; }
		}
	}
	.flexible-content {
		margin-bottom: 90px;
		h1, h2, h3 { color: $blueSupporting; }
		h2 {
			margin: 15px 0 60px;
			text-align: center;
		}
		.text-image-row {
			img {
				display: block;
				margin: 0 auto;
			}
			p.button-wrapper {
				color: $blueSupporting;
				display: inline-block;
				margin: 90px 0 0;
				position: absolute;
				right: 15px;
				a.button-link {
					margin-left: 20px;
					position: initial;
					svg path { fill: $blueSupporting; }
					@include touch-hover() {
						background: $blueSupporting !important;
						color: $white !important;
						svg path { fill: $white; }
					}
				}
			}
			.sr-left, .sr-right {
				a.button-link {
				    border-color: $blueSupporting;
				    color: $blueSupporting;
					@include touch-hover() {
				    	background: $blueSupporting !important;
				    	color: $white !important;
				    }
				}
				a {
					color: $blueSupporting;
					@include touch-hover() { color: $lightBlueSupporting; }
				}
			}
		}
	}
	.testimonials {
		h2 { color: $blueSupporting; }
		.testimonial-block { background: $lightBlueSupporting; }
	}
	.site-footer {
		#footer_achievements {
			p.handwritten { color: $signmasterPrimary; }
			h3 { color: $signmasterSecondary; }
		}
		#footer_green {
			background: $blueSupporting;
			h3, ul li.current-menu-item a, .social a, .contact p { color: $lightBlueSupporting !important; }
			ul li a, .contact a {
				@include touch-hover() { color: $lightBlueSupporting !important; }
			}
			.contact p.jumbo { color: $white !important; }
			#scroll_top {
				filter: hue-rotate(29deg);
				svg g circle {
					fill: rgba(29, 62, 99, 0.5);
				}
			}
		}
	}
	form input[type="submit"] { 
		@include touch-hover() { color: $blueSupporting !important; }
	}
}

body.page-id-17,
body.page-id-10112 {
	h2 { font-size: 40px; }
	h3 { font-size: 32px; }
	.intro { margin-bottom: 0; }
	.flexible-content {
		.twocolumn { margin-bottom: 60px; }
		.text-image-row h3 { margin-top: 60px; }
	}
	.ctas .cta a.button-link {
		@include touch-hover() {
			background: $white !important;
			color: $greenSecondary !important;
		}
	}
}

.panel-signmaster-form{
	.wpcf7-response-output{
		margin-top: 0px;
		position:initial;
		display:none;
	}
	.container{
		clear:both;
	}
}
body.page-id-32 .intro .your-consent a{
    color:#fff;
}
.panel-signmaster-form{
	text-align: center;
}
