.page-template-page-initial-application-form {
	.container .header-flex {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.container .header-flex .content-wrap {
		flex: 3;
	}

	.container .form-title {
		margin-top: 64px;
		@media (max-width: 991px) {
			font-size: 36px;
		}
		@media (max-width: 767px) {
			padding: 0 16px;
			margin: 32px 0 8px 0;
		}
		@media (max-width: 600px) {
			font-size: 24px;
		}
	}

	@media (max-width: 991px) {
		.agency-form-logo {
			display: none;
		}
	}

	.container .content-group {
		margin-right: 64px;
		margin-bottom: 16px;
		@media (max-width: 767px) {
			padding: 0 16px;
			margin: 8px 0;
		}
	}

	.container .form-title {
		color: #00721A;
		font-weight: 700;
		font-family: Arial, Helvetica, sans-serif;
	}

	.container .header-flex .logo-wrap {
		flex: 1;
		text-align: right;
	}

	.container .agency-form-logo {
		position: relative;
		bottom: 64px;
	}

	.container .form-content {
		width: 100%;
	}


	/* Gravity Forms Styling */
	#gform_wrapper_20 {
		background-color: #e0f1ec;
		padding: 40px 80px;
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;
		margin-bottom: 96px;
		@media (max-width: 991px) {
			padding: 52px 64px;
		}
		@media (max-width: 767px) {
			padding: 48px 36px;
		}
		@media (max-width: 400px) {
			padding: 40px 12px;
		}

		.gravity-theme .gform_body {
			padding: 0 !important;
		}
		.gfield {
			padding: 0 !important;
		}

		.gform_heading .gform_title {
			color: #00721A;
			font-family: Arial, Helvetica, sans-serif;
			font-size: 24px;
			font-weight: 700;
			margin-bottom: 20px;
		}

		.gform-body .gravity_forms_heading,
		.gsection_title {
			color: #00721A;
			font-family: Arial, Helvetica, sans-serif;
			font-size: 24px;
			line-height: 32px;
			font-weight: 700;
		}

		.gfield_label {
			color: #00721A;
			font-size: 16px;
		}

		.gfield input[type=text],
		.gfield input[type=email],
		.gfield input[type=number],
		.gfield textarea {
			width: 100%;
			padding: 10px;
			border-radius: 5px;
			border: 1px solid #ccc;
		}

		.gfield input[type=number] {
			padding: 5px 10px;
			@media (max-width: 600px) {
				padding: 4px 10px;
			}
			@media (max-width: 480px) {
				padding: 2px 10px;
			}
		}

		.gform_footer input[type=submit] {
			background-color: #e0f1ec;
			color: #fff;
			padding: 12px 20px;
			border-radius: 0;
			border: none;
			cursor: pointer;
			margin-left: 16px;
			margin-top: -14px;
			@media (max-width: 641px) {
				padding: 6px 16px;
				margin-top: -8px;
			}
		}

		.gform_footer input[type=submit]:hover {
			background-color: #fff;
			color: #00721A;
		}
		.gform_footer input[type=submit]:active {
			transform: scale(0.95);
			color: #00721A;
			background-color: whitesmoke;
		}

		#field_submit {
			flex-direction: row;
		}

		.gform-field-label {
			color: #00721A;
		}

		.gform_fields {
			grid-column-gap: 32px !important;
			@media (max-width: 991px) {
				grid-column-gap: 4% !important;
				grid-row-gap: 24px !important;
			}
			@media (max-width: 641px) {
				display: flex;
				flex-direction: column;

				.gf-field__name {
				  order: 1;
				}
			  
				.gf-field__email {
				  order: 2;
				}
			  
				.gf-field__phone {
				  order: 3;
				}
			  
				.gf-field__street {
				  order: 4;
				}
			  
				.gf-field__city {
				  order: 5;
				}
			  
				.gf-field__postcode {
				  order: 6;
				}

				.checkbox_franchisee-contact {
					order: 7;
				}

				.checkbox_privacy-policy {
					order: 8;
				}
			}
		}
		.checkbox_franchisee-contact .gfield_consent_label,
		.checkbox_privacy-policy .gfield_consent_label {
			font-size: 15px;
		}
		.checkbox_franchisee-contact {
			margin-top: 8px;
		}
		.checkbox_privacy-policy {
			margin-top: -16px;
		}

		.gf_step {
			cursor: pointer;
		}
		.gf_step_number {
			color: #000;
			border: 2px solid #00701a80;
		}
		.gf_step:hover {
			.gf_step_number {
				border: 2px solid #00701acc;
				color: #00721a;
			}
			.gf_step_label {
				color: #00721a;
			}
		}
		.gf_step_active {
			.gf_step_number {
				color: #fff;
				background-color: #00721a;
			}
		}
		.gf_step_active:hover {
			.gf_step_number {
				background-color: #00721a;
				border: 2px solid #00721a;
				color: #fff;
			}
			.gf_step_label {
				color: #333333;
			}
		}
		.gf_step_number:before {
			background-color: #4c9f80;
			border: 2px solid #4c9f80;
		}
		.gf_step_completed:hover {
			.gf_step_number:before {
				background-color: #4c9e80d9;
				border: 2px solid #4c9f80;
			}
		}
		.gf_step_number:after {
			z-index: 2;
		}

		.gsection {
			border-bottom: 1px solid #00721a !important;
		}

		/* Button Alignment */
		.gform_page_footer.top_label {
			display: flex;
    		justify-content: center;
			@media (max-width: 600px) {
				flex-flow: column;
			}
		}

		/* Previous Button */
		.gform_page_footer .gform_previous_button {
			background-color: #e0f1ec;
			border: 2px solid #4c9f80;
			color: #4c9f80 !important;
			padding: 10px 20px;
			border-radius: 4px;
			margin-right: 10px;
			cursor: pointer;
			transition: background-color 0.3s, color 0.3s;
			@media (max-width: 600px) {
				margin: 0 0 8px 0;
			}
		}

		.gform_page_footer .gform_previous_button:hover {
			background-color: #4c9f80;
			color: #e0f1ec !important;
		}

		/* Next Button & Submit */
		.gform_page_footer .gform_next_button,
		.gform_page_footer .gform_button,
		.gform_footer input[type=submit], 
		.gform_footer input[type=reset], 
		.gform_footer input[type=button] {
			background-color: #00721a;
			border: 2px solid #00721a;
			color: #ffffff !important;
			padding: 10px 20px !important;
			border-radius: 4px;
			margin-right: 10px;
			cursor: pointer;
			transition: background-color 0.3s;
			@media (max-width: 600px) {
				margin: 8px 0;
			}
		}

		.gform_page_footer .gform_next_button:hover,
		.gform_page_footer .gform_button:hover,
		.gform_footer input[type=submit]:hover, 
		.gform_footer input[type=reset]:hover, 
		.gform_footer input[type=button]:hover {
			background-color: #00721bd9;
			border: 2px solid #00721bd9;
		}
		.gform_page_footer .gform_next_button:active,
		.gform_page_footer .gform_button:active,
		.gform_footer input[type=submit]:active, 
		.gform_footer input[type=reset]:active, 
		.gform_footer input[type=button]:active {
			background-color: #005a15;
			border: 2px solid #005a15;
		}

		/* Save & Continue Button */
		.gform_save_link {
			background-color: #fff !important;
			border: none !important;
			padding: 10px 20px;
			border-radius: 4px;
			cursor: pointer;
			transition: background-color 0.3s;
			margin-left: auto;
			@media (max-width: 600px) {
				margin: 24px 0 16px 0;
			}
		}

		.gform_save_link:hover {
			background-color: #fafafa !important;
		}
		.gform_save_link:active {
			background-color: #f5f5f5 !important;
		}

		.gf_page_steps {
			padding: 16px 16px 0 16px;
			@media (max-width: 600px) {
				display: none;
			}
		}
	}

	.container #gform_confirmation_message_20 {
		background-color: #e0f1ec !important;
		text-align: center !important;
		color: #00721A !important;
		font-size: 18px !important;
		font-weight: 700 !important;
	}

	.form_saved_message_emailform {
		.gform_body {
			padding: 0;
		}
		.gform_footer input[type=submit] {
			margin-left: 0 !important;
    		margin-top: -4px !important;
		}
	}

	.container .form_saved_message h2 {
		color: #00721A;
		font-weight: 700;
		font-family: Arial, Helvetica, sans-serif;
		font-size: 24px;
	}

	.container .form_saved_message_sent {
		background-color: #e0f1ec;
		text-align: center;
		padding: 16px;

		h2.saved_message_success { 
			color: #00721A;
			font-weight: 700;
			font-family: Arial, Helvetica, sans-serif;
			font-size: 30px;
		}

		p .saved_message_email { 
			text-decoration: underline;
		}
	}
	
	.gform_wrapper.gravity-theme .gf_step_active .gf_step_number {
		color: white;
		background-color: #4f7389;
	}
}
