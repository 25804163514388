a.button-link,
span.button-link,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
    color: $greenSecondary;
    cursor: pointer;
    border: 2px solid $greenSecondary;
    display: inline-block;
    font-family: $headingFont;
    font-size: 15px;
    font-weight: 700;
    line-height: 1;
    padding: 15px 40px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    @include transition-no-touch(all ease $animationSpeed);
    @include touch-hover() { text-decoration: none !important; }
    &:active, &:focus { text-decoration: none !important; }
}

input[type="button"]:focus,
input[type="submit"]:focus,
input[type="button"]:active,
input[type="submit"]:active {
    outline: 0 !important;
    text-decoration: none !important;
}

* { -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important; }

a.button-link-svg,
span.button-link-svg {
    padding: 15px 45px 15px 25px;
    svg {
        height: 15px;
        margin-left: 15px;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        width: 15px;
        path {
            fill: $greenSecondary;
            transition: fill ease $animationSpeed;
        }
    }
    @include touch-hover() {
        svg path { fill: $white; }
    }
}

.cc-banner .cc-compliance .cc-btn {
    @include transition-no-touch(all ease $animationSpeed);
    @include touch-hover() {
        background: $white !important;
        color: $redSecondary !important;
    }
}

#chat-butt {
	position: fixed;
	bottom: 0px;
	right: 0px;
	width: 110px;
	height: 50px;
	padding: 0px;
	margin: 10px 20px;
	overflow: visible;
	opacity: 1;
	border: 0px;
	cursor: pointer;
	z-index: 999998;
	transition-duration: 250ms;
	transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	transition-property: opacity, top, bottom;
}
