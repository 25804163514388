.page-template-block-template {
	h1, h2 {
		margin: 36px 0 24px;
	}
	h1 { font-size: 40px; }
	h2 { font-size: 32px; }
	h3 { font-size: 22px; }
	h4 { font-size: 20px; }
	h5 { font-size: 18px; }
	h6 { font-size: 16px; }

	h2.handwritten {
		font-size: 48px;
	}

	.site-header a,
	.quote-form-desktop .form-toggle #close,
	.site-footer #footer_green ul li a,
	.site-footer #footer_green .contact a,
	.site-header .menu-wrapper ul li a {

		&:hover,
		&:focus {
			color: #ffffffcc !important;
		}
	}

	.site-header {
		.menu-toggle #menu:hover,
		.menu-toggle #close:hover {
			color: #ffffffcc !important;
		}

		.menu-toggle #menu svg path,
		.menu-toggle #close:hover svg path,
		.menu-toggle #menu svg rect {
			fill: #ffffffcc !important;
		}

		.menu-toggle #menu svg rect {
			fill: #fff !important;
		}

		.menu-wrapper ul li.current-menu-item a,
		.menu-wrapper ul li.current-menu-item a:hover {
			color: var(--active-menu-color, #fff) !important;
		}
	}

	.site-footer {
		#footer_green {
			#scroll_top {
				filter: hue-rotate(29deg);

				svg g circle {
					fill: rgba(0, 0, 0, 0.5);
				}
			}

			h3 {
				color: white;
				font-size: 16px;
			}

			.social a,
			.contact p:not(.jumbo) {
				color: var(--active-menu-color, #ffffffcc) !important;
			}

			.social {
				a#facebook:hover {
					color: $facebook !important;
				}
				a#linkedin:hover {
					color: $linkedin !important;
				}
				a#google_plus:hover {
					color: $googlePlus !important;
				}
				a#twitter:hover {
					color: $twitter !important;
				}
				a#instagram:hover {
					color: $instagram !important;
				}
			}

			ul li.current-menu-item a,
			ul li.current-menu-item a:hover {
				color: var(--active-menu-color, #fff) !important;
			}
		}

		#footer_achievements {

			h3,
			p.handwritten {
				color: #fff;
			}
		}
	}

	.quote-form-desktop .form-toggle #close:hover svg path {
		fill: #ffffffcc !important;
	}
	
	.container article {
		margin-top: 64px;
		margin-bottom: 64px;
	}

	.container article > h1:first-child {
		color: #4b9f80;
		font-family: Arial, Helvetica, sans-serif;
		font-size: 36px;
		line-height: 40px;
		font-weight: 400;
		margin-top: 64px;
		margin-bottom: 24px;
		padding-left: 128px;
		padding-right: 128px;
		@media (max-width: 1230px) {
			padding-left: 16px;
			padding-right: 16px;
		}
		@media (max-width: 600px) {
			font-size: 30px;
			line-height: 36px;
		}
	}

	body .container article p {
		padding-left: 16px !important;
		padding-right: 16px !important;
	}

	@media (max-width: 1230px) {
		.banner-title-mobile {
			font-size: 40px !important;
		}
	}

	@media (max-width: 991px) {
		.banner-title-mobile {
			font-size: 30px !important;
		}
		.h2-title-mobile {
			font-size: 36px !important;
		}
		.mx-0-mobile {
			margin-right: 0px !important;
			margin-left: 0px !important;
		}
		.p-mobile {
			font-size: 16px !important;
		}
	}

	@media (max-width: 767px) {
		.banner-title-mobile {
			font-size: 24px !important;
			text-align: center;
		}
		.h2-title-mobile {
			font-size: 24px !important;
		}
		.p-mobile {
			font-size: 16px !important;
		}
	}

	// Gravity Form Styling
	.gform_wrapper {
		.gform_heading .gform_title {
			color: #00721A;
			font-family: Arial, Helvetica, sans-serif;
			font-size: 30px;
			font-weight: 700;
			margin-bottom: 20px;
		}

		.gform-body {
			#gform_submit_button_1:hover,
			.gform-button:hover {
				color: #000 !important;
			}
	
			.gfield_consent_label,
			.gform_wrapper.gravity-theme .gfield_consent_label,
			.ginput_container.ginput_container_consent>label>span {
				color: white !important;
			}
		}

		.gform-body .gravity_forms_heading {
			color: #00721A;
			font-family: Arial, Helvetica, sans-serif;
			font-size: 30px;
			line-height: 32px;
			font-weight: 700;
		}
	
		.gfield_label {
			color: #00721A;
			font-weight: bold;
			font-size: 16px;
		}
		.gfield input:not(#gform_submit_button_1),
		.gfield textarea,
		select {
			border: none;
		}

		.gform_footer input[type=submit] {
			font-family: Arial, Helvetica, sans-serif !important;
			font-size: 16px !important;
		}
		.gform_confirmation_message {
			text-align: center !important;
			color: #00721A !important;
			font-size: 18px !important;
			font-weight: 700 !important;
		}
	}
	.gform-mobile-padding {
		@media (max-width: 767px) {
			padding: 48px 36px !important;
		}
		@media (max-width: 400px) {
			padding: 40px 12px !important;
		}
	}


	// Gravity Forms Block Template Styling
	.slide-in-form {
		position: absolute;
		right: -300%;
		width: 100vw;
		transition: right 0.3s ease-in-out;
		z-index: 1000;
		margin: 0;
	}
	
	.slide-in-form.show {
		right: 0;
	}
	
	.form-dismiss {
		cursor: pointer;
		z-index: 1100;
	}
	
	.form-dismiss-row {
		position: relative;
		top: 36px;
	}
	
	.form-dismiss svg {
		width: 30px;
	}
	
	.form-dismiss svg path {
		fill: #fff;
	}
	
	.trigger-slide-in-form {
		color: #5e7faf;
		border-color: #5e7faf;
		background-color: transparent;
		font-size: 16px;
	}
}
