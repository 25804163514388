/* AgencyKeySafe Page */
body.page-id-11640 {
	background: none;

	#main {
		background-image: linear-gradient(to bottom, #fff 0, #fff 100%), linear-gradient(to bottom, #e53137 0, #e53137 100%);
		background-clip: content-box, padding-box;
	}

	h1, h2, h3, h4, h5, h6 {
		color: #e53137;
	}

	.site-header a,
	.quote-form-desktop .form-toggle #close,
	.site-footer #footer_green ul li a,
	.site-footer #footer_green .contact a,
	.site-header .menu-wrapper ul li a {

		&:hover,
		&:focus {
			color: #ffffffcc !important;
		}
	}

	.agencykeysafe_content a {
		color: #e53137;

		&:hover {
			color: #e63339cc;
		}
	}

	.site-footer #footer_green h3,
	.site-header .menu-wrapper ul li.current-menu-item a,
	.site-footer #footer_green ul li.current-menu-item a {
		color: #ffb4b7cc !important;

		&:hover {
			color: #ffb4b7cc !important;
		}
	}

	.site-header {
		background-color: #c91c32;

		.container-fluid.greenBG,
		.menu-wrapper {
			background-color: #c91c32;
		}

		.menu-toggle #menu:hover,
		.menu-toggle #close:hover {
			color: #ffffffcc !important;
		}

		.menu-toggle #menu svg path,
		.menu-toggle #close:hover svg path,
		.menu-toggle #menu svg rect {
			fill: #ffffffcc !important;
		}

		.menu-toggle #menu svg rect {
			fill: #fff !important;
		}
	}

	.banner-title {
		h1 {
			span {
				color: #e53137;
			}
		}
	}

	.introqr {
		#sm4_form_trigger_1 {
			position: absolute;
			top: 75px;
			right: 0;
			color: #e53137 !important;
			border-color: #e53137;

			svg path {
				fill: #e53137;
			}

			@media (max-width: 1229px) {
				top: 32px;
			}

			@media (max-width: 991px) {
				top: 124px;
				left: 50%;
				transform: translateX(-50%);
				-ms-transform: translateX(-50%);
				width: 200px;
			}

			@media (max-width: 767px) {
				width: 186px;
			}

			@media (max-width: 479px) {
				top: 100px;
			}
		}
	}

	.quote-form-mobile,
	.quote-form-desktop,
	#quote_mobile_form_toggle {
		background-color: #c91c32 !important;
	}

	.quote-form-desktop .form-toggle #close:hover svg path {
		fill: #ffffffcc !important;
	}

	#signmaster_form_1 {
		background-color: #c91c32de;
		padding-bottom: 16px;

		.title,
		.sub-title {
			padding-right: 16px;
			padding-left: 16px;

		}

		.title {
			margin-top: 16px;
		}

		.sub-title {
			margin-bottom: 0px;
		}

		.form-dismiss {
			@media (min-width: 1920px) {
				position: fixed;
				top: 46px;
				right: 118px;
			}

			@media (max-width: 767px) {
				margin-top: 16px;
				margin-bottom: 80px;
			}

			@media (max-width: 479px) {
				margin-top: -12px;
			}
		}

		.title,
		.sub-title {
			@media (max-width: 479px) {
				text-align: center;
			}
		}

		#c500pro {
			margin: 16px auto;
		}
	}

	.testimonials .testimonial-block,
	.site-footer #footer_green {
		background-color: #e53137;
	}

	.site-footer {
		#footer_green {
			#scroll_top {
				filter: hue-rotate(29deg);

				svg g circle {
					fill: rgba(195, 21, 44, 0.7);
				}
			}

			h3 {
				color: white;
				font-size: 16px;
			}

			.social a,
			.contact p {
				color: #ffffffcc
			}
		}

		#footer_achievements {

			h3,
			p.handwritten {
				color: #e53137;
			}
		}
	}

	.gform_wrapper .handwritten.thank-you:not(.quote-form-desktop .handwritten.thank-you, .quote-form-mobile .handwritten.thank-you) {
		color: #fff;

		@media (max-width: 310px) {
			display: none;
		}
	}

	.gform-body {

		#gform_submit_button_1:hover,
		.gform-button:hover {
			color: #575f65 !important;
		}

		.gfield_consent_label,
		.gform_wrapper.gravity-theme .gfield_consent_label,
		.ginput_container.ginput_container_consent>label>span {
			color: white !important;
		}
	}

	.gform_validation_errors {
		width: auto !important;
		margin-right: 16px !important;
		margin-left: 16px !important;
		padding: 16px 16px 16px 16px !important;
	}

	.gform_confirmation_message {
		font-weight: bold;
		font-size: 20px;
	}

	.flexible-content .sr-left li img,
	.flexible-content .sr-right li img {
		width: 64px;
	}
}