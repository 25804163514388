.page-template-page-franchise_resale_opportunities {
	.acf_intro-fields_block .intro {
		margin-bottom: 24px;
	}
	.acf_intro-fields_block .intro h2 {
		color: #4b9f80;
		text-align: center;
		font-weight: 400;
		font-family: Arial, Helvetica, sans-serif;
		margin: 50px 64px 35px;
		font-size: 36px;
		line-height: 40px;
		@media (max-width: 1230px) {
			margin: 50px 32px 35px;
		}
		@media (max-width: 767px) {
			margin: 50px 16px 35px;
			font-size: 30px;
			line-height: 36px;
		}
		@media (max-width: 400px) {
			font-size: 24px;
			line-height: 32px;
		}
	}

	.acf_intro-fields_block .intro .row {
		margin-left:  0px;
   		margin-right: 0px;
		@media (min-width: 1230px) {
			padding: 0 96px;
		}
		@media (max-width: 600px) {
			padding: 0 16px;
		}
		@media (max-width: 576px) {
			padding: 0;
		}
	}

	.acf_intro-fields_block .centeredonecolumn {
		font-size: 18px;
		line-height: 28px;
		@media (max-width: 576px) {
			font-size: 16px;
			line-height: 24px;
		}
	}

	.acf_squares_block__heading {
		color: #4b9f80;
		font-weight: 400;
		font-family: Arial, Helvetica, sans-serif;
		font-size: 30px;
		line-height: 36px;
		margin-bottom: 8px;
		@media (max-width: 767px) {
			font-size: 24px;
			line-height: 32px;
		}
		@media (max-width: 400px) {
			font-size: 20px;
			line-height: 28px;
		}
	}

	.acf_squares_block__sub-heading {
		font-size: 18px;
		line-height: 28px;
		@media (max-width: 576px) {
			font-size: 16px;
			line-height: 24px;
		}
	}
}
