.testimonials {
	margin-bottom: 90px;
	h2 {
		color: $greenSecondary;
		font-size: 48px;
		margin: 0 0 35px;
		text-align: center;
	}
	.testimonialsContent{
		margin-bottom: 30px;
	}
	.testimonial-block {
		background: $greenSecondary;
		min-height: 310px;
		position: relative;
		width: 1000px;
		img#quote_mark {
			position: absolute;
			top: 25px;
		}
		.testimonial-wrapper {
			height: 100%;
			opacity: 0;
			padding-top: 25px;
			position: absolute;
			transition: all ease $animationSpeed;
			width: 570px;
			p, .quote { color: $white; }
			.quote {
				font-style: italic;
				line-height: 1.8em;
				margin-bottom: 10px;
			}
			p.quotee {
				font-weight: 700;
				line-height: 1.4;
				margin: 12px 0 0;
			}
			a {
				color: $white;
				font-weight: 300;
				@include touch-hover() { color: $greenSupporting; }
			}
			.img-wrapper {
				top: 95px;
				overflow: hidden;
				position: absolute;
				transform: translateY(-50%);
				img {
					border-radius: 65px;
					-webkit-border-radius: 65px;
					-moz-border-radius: 65px;
					height: 130px;
					width: 130px;
				}
			}
			&.active { opacity: 1; }
		}
	}
	.left-testimonial {
		float: right;
		padding-left: 430px;
		img#quote_mark { right: 575px; }
		.testimonial-wrapper {
			right: 1000px;
			padding-right: 15px;
			.quote, p.quotee { padding-right: 140px; }
			.img-wrapper { right: 15px; }
			&.active { right: 0; }
		}
	}
	.right-testimonial {
		padding-right: 430px;
		text-align: right;
		img#quote_mark {
			filter: FlipH;
			left: 575px;
			transform: scaleX(-1);
			-ms-transform: scaleX(-1);
		}
		.testimonial-wrapper {
			padding-left: 15px;
			left: 1000px;
			.quote, p.quotee { padding-left: 140px; }
			.img-wrapper { left: 15px; }
			&.active { left: 0; }
		}
	}
	a#view_more {
		display: none;
		float: right;
		margin-top: 30px;
		position: relative;
		right: initial;
		width: 175px;
		#refresh_icon { height: 20px; }
		&.active { display: block; }
		@include touch-hover() {
			background: $greenSecondary;
			color: $white;
			text-decoration: none !important;
		}
	}
}

.testimonialSpacer .image-wrapper a img {
	height: 75px;
	width: 75px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
    -moz-border-radius: 50%;
}
