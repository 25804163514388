body.page-id-310 {
	.banner .banner-title {
		h1 {
			font-size: 21px;
		}
		form#operator_search {
			margin-bottom: 15px;
			input[type=text] {
				height: 35px;
			}
			input[type=submit] {
				font-size: 15px;
			}
		}
		p {
			font-size: 14px;
		}
	}
	.banner .banner-title-wrapper {
		position: relative;
	}
}

.post-card, .owl-carousel .post-card {
	.post-detail {
		height: 100px;
		.post-detail-content { padding-top: 10px; }
		p.type { font-size: 12px; }
		p.name { font-size: 16px; }
		p.date {
			bottom: 0;
			font-size: 10px;
		}
	}
}

.testimonials {
	.testimonial-block {
		height: 395px;
		.testimonial-wrapper {
			padding: 20px;
			.img-wrapper img {
				border-radius: 45px;
				-webkit-border-radius: 45px;
				-moz-border-radius: 45px;
				height: 90px;
				margin: 10px auto;
				width: 90px;
			}
		}
	}
	.left-testimonial img#quote_mark {
		left: 5px !important;
		top: 15px;
	}
}

.ctas .cta .cta-message p { font-size: 16px; }

#local_operator_cta {
	.cta-message {
		padding: 5px 15px;
		p { font-size: 14px; }
	}
	.form-wrapper form {
		input[type=text] {
			float: right;
			font-size: 14px;
			height: 30px;
			margin-bottom: 8px;
			width: 95%;
		}
		input[type=submit] {
			font-size: 10px;
	    	padding: 10px 30px;
		}
	}
	.form-wrapper{
		top: 10px;
	}
}

body.page-id-4817{
	#map{ height:880px; }
	.intro{
		margin-bottom:20px;
		h2{ margin-bottom:0px;}
	}
}

.pai-box img { width: 100%; }

.map {
	min-height: 735px;
	.form-panel { padding: 30px 15px 0; }
}

body.single-area .intro a.button-link { top: 60px; }

body.page-id-4806 .intro .form-panel {
	margin-left: -15px;
	margin-right: -15px;
	.handwrittenWrapper{ text-align:left;}
}

body.error404 .intro {
	margin-top: 30px;
	img {
		margin-top: 5px;
		width: 50px;
	}
	p { padding-left: 70px; }
}

body.page-id-32 .flexible-content .text-image-row p.button-wrapper a.sm4-form-trigger { margin-top: 5px; }

#pai_signup_form {
	height: 360px;
}

body.page-id-4817 .map{
	.form-panel {
		padding: 30px 0 0;
		form p { font-size: 18px; }
	}
	.requestForm{
		.handwritten{ font-size:28px; padding-top:30px;}
	}
}

.contactMap{
	height: 890px;
	width: 100%;
}

.categories{
	ul{
		li {
			font-size: 11px;
			width: 49%;
		}
	}
}
body.page-id-4531 {
	.cards{
		margin-top: 80px;
	}
	.spotlight{
		h2 {
			margin-top: 0;
			font-size: 24px;
		}
	}
	.cards .card .card-detail .card-content{
		a.button-link {
			margin-bottom: 15px;
		}
		p{ display:none; }
	}
	.post-card .post-detail{
		padding: 35px 25px 35px 25px;
	}
}
body.single-pai {
	.pagination{
		a.button-link-svg{
			margin-top:60px;
		}
	}
}

//contact us page handwritten mobile override
body.page-id-22{
	.form-panel{
		.handFirst{
			font-family: Lato, sans-serif;
			margin-bottom: 20px;
		}
	}
}

//apply online page handwritten mobile override
body.page-id-40{
	.form-panel{
		.handFirst{
			font-family: Lato, sans-serif;
		}
	}
}
.testimonial-block {
	min-height: 100px !important;
}
.flexible-content {
	.twocolumn p { text-align:center; }
	.sr-left, .sr-right {
		img { max-width: 100%; }
	}
}
body.page-template-default .intro .two-column-text p{
	text-align:center;
}
#operator_search{
	margin-top:75px;
}
body.page-id-310 #operator_search{
	margin-top:0px;
}

.operatorSubmitHolder svg{
	top:18px;
}
.fixed-form{ height:0px;}

a#quote_mobile_form_toggle {
	cursor: pointer;
	display: block;
	font-family: Lato,sans-serif;
	font-weight: 700;
	height: 40px;
	padding-top: 8px;
	text-transform: uppercase;
	width: 100%;
	background: rgba(76,159,128,.9);
	bottom: 0;
	height: 40px;
	overflow: auto;
	position: fixed;
	text-align: center;
	width: 100%;
	transition: height .3s ease;
	z-index: 2;
	svg{
		height: 20px;
		margin-left: 15px;
		position: absolute;
		top: 9px;
		width: 20px;
		path{ fill:white;}
	}
	color:white;
}

.quote-form-content {
	overflow: scroll;
	background: rgba(76,159,128,.9);
	position:fixed;
	width: 100%;
	height: 0;
	bottom:0px;
	left:initial;
	top:initial;
	.form-toggle{
		display:block;
		margin-bottom:30px;
	}
	&.active{
		height: 100%;
		padding-top: 20px;
	}
	#close{
		left:0px;
	}
}
body.page-id-32 .quote-form-content, body.page-id-17 .quote-form-content{
	background: rgba(84, 128, 177, .9);
}
.askUs{ right:0px; }

.quote-form-desktop{
	display:none;
	opacity:0;
}

iframe {
	height: 170px;
	width: 290px;
}


/* form styles */
form input[type=email], form input[type=text] { height: 35px; }

form input[type=email], form input[type=text], form textarea {
	font-size: 14px;
	padding: 7px 10px;
}

form textarea {
	height: 140px;
	line-height: 1.4;
}

.quote-form-mobile {
	.form-toggle {
		height: 60px;
		span.handwritten { font-size: 22px; }
	}
	div.wpcf7-response-output {
		bottom: 10px;
		box-sizing: border-box;
		margin: 0;
		padding: 10px 15px;
		position: absolute;
		width: calc(100% - 50px);
	}
}

#pai_signup_form {
	.wpcf7-response-output{
		margin-top:0px;
		margin-left: 15px;
		margin-right: 15px;
	}
}

.page-template-page-download_prospectus .form-panel form .wpcf7-response-output{
	margin-left: 0px;
	margin-right:0px;
}

.single-area form .wpcf7-response-output{
    left:0px;
    margin-left: 15px;
    margin-right: 15px;
}


/* Download Prospectus page */
body.page-template-page-download_prospectus_2 .banner-title-wrapper .banner-title .form-holder { padding: 0 !important; }

body.page-id-4806 .intro { margin-bottom: 0; }

.banner .banner-title .download-link {
	margin: 25px 0 30px;
	width: 100%;
}

.affiliations .affiliation-item a img {
    height: auto;
    width: auto;
}

.awards .award-item {
	&.wide {
		width: 49%;
	}
}

.gallery .modal .modal-image-wrapper iframe {
    height: 150px;
    width: 260px;
}

.cards {
	.col-xs-9 {
		width: 100%;
	}
}